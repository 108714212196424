import React, { Fragment } from 'react';
import PhotoModal from '../../modals/PhotoModal';
import LazyLoad from 'react-lazyload';
import './styles.css';

export class ProfilePhotoItem extends React.Component {
    render() {
        return (
            <Fragment>
                <LazyLoad>
                    <img onClick={() => this.photoModal.openModal()} src={this.props.photo.url} alt="" />
                </LazyLoad>
                <PhotoModal onRef={ref => (this.photoModal = ref)} photoId={this.props.photo._id}></PhotoModal>
            </Fragment>
        )
    }
}

export default ProfilePhotoItem;
