import React, { useEffect } from 'react';
import { getReviews } from '../../actions/reviews';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Spinner from '../layout/Spinner';
import ProfileReviewItem from './ProfileReviewItem';
import { capitalize } from 'lodash';
import { ReviewFilter } from './ReviewFilter';

const Reviews = ({
	countryKey,
	reviews: { reviews, loading },
	getReviews,
	auth
}) => {
	useEffect(() => {
		getReviews(countryKey);
	}, [getReviews, countryKey]);

		return (
		<div>
			<h3>{`Reviews(${reviews?.length})`}</h3>
			{!countryKey && <ReviewFilter countryKey={countryKey}/>}
			<h4>{reviews && countryKey ? `Reviews about ${capitalize(countryKey)}` : ''}</h4>
			{loading ? (
			<Spinner />
			) : (
			reviews.map(review => (
				<ProfileReviewItem review={review} key={review._id} />
			))
			)}
		</div>
	);
};

Reviews.propTypes = {
	reviews: PropTypes.object.isRequired,
	auth: PropTypes.object.isRequired,
getReviews: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
	reviews: state.reviews,
	auth: state.auth
});

export default connect(mapStateToProps, { getReviews })(Reviews);
