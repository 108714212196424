import React from 'react';

const Comment = ({ comment }) => {
    if (typeof comment === 'string') {
        return <p>{comment}</p>;
    }

    if (Array.isArray(comment)) {
        return comment.map(item => <p>{item}</p>);
    }
}

export const YoutubeComment = ({ comment }) => {
    return (
        <div className="book-quote">
            Youtube comment: <br/><br/>
            <Comment comment={comment} />
        </div>
    );
};