import React from 'react';
const price = 9.99;

export const BooksSold = ({ total, today }) => {
    const copiesWord = today === 1 ? 'copy' : 'copies';

    if (total && today) {
        return (
            <div style={{ marginBottom: '1rem', color: 'white' }}>
                {/* {total} copies sold (or ${total * price}). {today} {copiesWord} sold today (${today * price}). */}
                {total} copies sold. {today} {copiesWord} sold today.
            </div>
        )
    }
    if (total) {
        return (
            <div style={{ marginBottom: '1rem', color: 'white' }}>
                {/* {total} copies sold (or ${total * price}). */}
                {total} copies sold.
            </div>
        )
    }
};