export const SURVEY_DATA = {
    section_one: [
        {
            key: 'countryKey',
            required: true,
            title: 'Which country have you immigrated to?',
            label: 'Select country...',
            type: 'string',
            inputType: 'text',
            enum: 'country',
            filter: true,
            filterTitle: 'Filter by country'
        },
        {
            key: 'text',
            title: 'Your experience, comments, thoughts, suggestions for potential new immigrants',
            label: 'Tell is your story',
            type: 'string',
            inputType: 'text',
            lines: 12
        },
    ],
    section_two: [
        {
            key: 'origin',
            title: 'What is your home country?',
            label: 'Select country of origin...',
            type: 'string',
            inputType: 'text',
            enum: 'country',
            filter: true,
            filterTitle: 'Filter by country of origin',
        },
        {
            key: 'year',
            title: 'What year did you immigrate?',
            label: 'year',
            type: 'string',
            inputType: 'text',
            enum: 'year'
        },
        {
            key: 'satisfaction_level',
            title: 'Rate your level of satiscation with a new country from 1 to 10',
            label: '1 to 10 rate',
            type: 'string',
            inputType: 'text',
            enum: 'rate'
        },
        {
            key: 'still_there',
            title: 'Are you still in the country?',
            inputType: 'checkbox',
            type: 'boolean',
        },
        {
            key: 'move_again',
            title: 'Would you move again?',
            inputType: 'checkbox',
            type: 'boolean',
        },
        {
            key: 'savings_brought',
            title: 'How much money do you think is necessary for an immigrant to bring in the country to feel comfortable and be able to settle',
            label: 'Select savings to bring...',
            type: 'string',
            inputType: 'text',
            enum: 'money'
        },
        {
            key: 'pros',
            title: 'What are the pros of the country?',
            label: 'Pros',
            type: 'string',
            inputType: 'text',
            lines: 3
        },
        {
            key: 'cons',
            title: 'What are the cons of the country?',
            label: 'Cons',
            type: 'string',
            inputType: 'text',
            lines: 3
        }
    ],
    section_three: [
        {
            key: 'age',
            title: 'Your age',
            label: 'Age',
            type: 'string',
            inputType: 'text'
        },
        {
            key: 'family_status',
            title: 'Family status - single / married with children / married no children ',
            label: 'Family status',
            type: 'string',
            inputType: 'text',
            enum: 'family'
        },
        {
            key: 'city_first',
            title: 'First city where you moved two',
            label: 'First city',
            type: 'string',
            inputType: 'text'
        },
        {
            key: 'city_now',
            title: 'City where you live now',
            label: 'Current city',
            type: 'string',
            inputType: 'text'
        },
        {
            key: 'profession',
            title: 'Your profession',
            label: 'Profession',
            type: 'string',
            inputType: 'text'
        },
        {
            key: 'profession_spouse',
            title: 'Your spouse profession (if applicable)',
            label: 'Spouse profession',
            type: 'string',
            inputType: 'text'
        }
    ],
    section_four: [
        {
            key: 'expenses',
            title: 'Your household expenses per year',
            label: 'Annual expenses',
            type: 'string',
            inputType: 'text',
            enum: 'money'
        },
        {
            key: 'income',
            title: 'Your family annual income',
            label: 'Annual Income',
            type: 'string',
            inputType: 'text',
            enum: 'money'
        },
        {
            key: 'property_owner',
            title: 'Do you own a property in your new country',
            inputType: 'checkbox',
            type: 'boolean'
        }
    ],
    section_five: [
        {
            key: 'email',
            title: 'Your email (optional)',
            label: 'e-mail',
            type: 'string',
            inputType: 'text'
        },
        {
            key: 'name',
            title: 'Your name (optional)',
            label: 'Name',
            type: 'string',
            inputType: 'text'
        },
        {
            key: 'subscribe',
            title: 'Do you want to subscribe to our service news and announcements? (optional)',
            type: 'boolean',
            inputType: 'checkbox'
        }
    ]
};