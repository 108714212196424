import React, { useState, useEffect } from 'react';
import { addReview } from '../../actions/reviews';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Alert from '../layout/Alert';
import { setAlert } from '../../actions/alert';

const ProfileAddReview = ({ countryKey, auth, addReview, setAlert }) => {
  const [formData, setFormData] = useState({
	reviewText: ''
  });

  const { reviewText } = formData;

  const onChange = e =>
	setFormData({ ...formData, [e.target.name]: e.target.value });

    useEffect(() => {
        console.log("ZZZ", formData);
    }, [formData])

  const onSubmit = e => {
	e.preventDefault();
	if (!formData.reviewText) {
	  setAlert(
		'You cant post an empty review, please write something',
		'danger'
	  );
	} else {
		addReview(
			formData.reviewText,
			countryKey,
            Number.parseInt(formData.priority),
			auth?.user?.name,
			auth?.user?.avatar
		);
		setFormData({ ...formData, reviewText: '' });
	}
  };

	return (
		<div>
			<Alert />
			<div className="bg-info text-white">
			</div>
			<form className="mt-3" onSubmit={e => onSubmit(e)} style={{ display: 'flex', flexDirection: 'column' }}>
				<textarea
					rows="4"
					value={reviewText}
					name="reviewText"
					onChange={e => onChange(e)}
					placeholder={'Leave a comment if you have something to say about this country'}
					style={{ width: '80%' }}
					className="p-2"
				/>
                <input
                    onChange={e => onChange(e)}
                    defaultValue={0}
                    name='priority'
                    title="Priority"
                    type="number"
                />
				<button className="btn btn-info mt-2 mb-2" type="submit" style={{ width: '100px' }}>Submit</button>
			</form>
		</div>
  );
};

ProfileAddReview.propTypes = {
	countryKey: PropTypes.string.isRequired,
	addReview: PropTypes.func.isRequired,
	setAlert: PropTypes.func.isRequired,
	auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
	auth: state.auth
});

export default connect(mapStateToProps, { addReview, setAlert })(
	ProfileAddReview
);
