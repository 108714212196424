import axios from 'axios';

// Submit Survey
export const submitSurvey = async (survey) => {
	const config = {
		headers: {
			'Content-Type': 'application/json',
		},
	};
	const body = JSON.stringify(survey);

	try {
		await axios.post('/api/reviews', body, config);
	}
	catch (error) {
		const errors = error.response.data.errors;
	}
};
