import React, { useState } from 'react';
import { SURVEY_DATA } from '../../utils/survey/SurveyData';
import Select from 'react-select';
import { getSelectOptions } from '../../utils/survey/SurveyHelpers';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { submitSurvey } from '../../actions/survey';
import '../../assets/css/components/survey.css';

const Survey = ({ auth: { user } }) => {
	const [formData, setFormData] = useState({});
    const [loading, setLoading] = useState(false);

	const onChangeSelect = (newValue, key) => {
		setFormData({ ...formData, [key]: newValue.value });
	};

	const onChangeInput = (e, key) => {
		setFormData({ ...formData, [key]: e.target.value });
	};

	const onChangeBool = (e, key) => {
		setFormData({ ...formData, [key]: e.target.checked });
	};

	const onSubmit = async () => {
        const data = { ...formData };
		if (user) {
            data.email = user.email
            data.name = user.name;
		}
        data.isSurvey = true;
        setLoading(true);
        await submitSurvey(data);
        setLoading(false);
	};

	return (
		<div>
			<div className="survey-section m-4">
				<p>
                    Please answer the following questions. The servey can be completed in just a couple minutes.
                </p>
				<p>
					Feel free to skip a question if you don't feel comfortable to answer.
				</p>
				<p>
					Signing up and providing your email is optional. You can complete the survey anonymously.
				</p>
				<p>
					If you choose to sign up, you will be able to access, edit and delete your
					answers as well as access any services that we plan to design in the future.
				</p>
				<p>
					Thank you!
				</p>
				{Object.entries(SURVEY_DATA).map(([sectionKey, sectionValue], sectionIndex) => {
					if (sectionIndex === 3 && user) {
						return null;
					}
					return (
						<>
						<h4>Section {sectionIndex + 1}</h4>
							{sectionValue.map(item => {
								if (item.enum) {
									return (
										<div className="form-group">
											<small className="form-text">{item.title}</small>
											<Select
												options={getSelectOptions(item.enum)}
												className={`survey-input-select`}
												type={item.inputType}
												placeholder={item.label}
												name={item.title}
												onChange={(newValue) => onChangeSelect(newValue, item.key)}
											/>
										</div>
									);
								}
								else if (item.lines) {
									return (
										<div className="form-group">
											<small className="form-text">{item.title}</small>
											<textarea
												className={`survey-input-${item.type}`}
												type={item.inputType}
												placeholder={item.label}
												name={item.title}
												value={formData[item.key]}
												onChange={(e) => onChangeInput(e, item.key)}
												rows={item.lines}
											/>
										</div>
									);
								}
								else {
									return (
										<div className="form-group">
											<small className="form-text">{item.title}</small>
											<input
												className={`survey-input-${item.type}`}
												type={item.inputType}
												placeholder={item.label}
												name={item.title}
												value={formData[item.key]}
												onChange={(e) => item.type === 'boolean' ? onChangeBool(e, item.key) : onChangeInput(e, item.key)}
											/>
										</div>
									);
								}
							})}
					</>
					);
			    })}
				<button className="btn btn-primary submit-btn" onClick={onSubmit} disabled={loading}>Submit</button>
			</div>
		</div>
	);
}

Survey.propTypes = {
    auth: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
    auth: state.auth
});

export default connect(mapStateToProps)(Survey);
