import React from 'react';

export const CostOfLife = () => {
	return (
		<div>
			<h2>Example of the Cost of Life in Canada</h2>
			<div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
				<div style={{ flex: '1', minWidth: '300' }}>
					<h4>Scenario 1</h4>
					<ul>
						<li>a family with 1-2 children </li>
						<li>one of the adults is an experienced worker on a high-paying job</li>
						<li>$100,000 salary (considered to be a high salary in Canada)</li>
						<li>a 5 year old car loan</li>
						<li>renting a 2-3 bedroom apartment or an old townhouse</li>
					</ul>
					<table className="mb-4" style={{ width: '400px' }}>
						<tbody>
							<tr>
								<td width="300">Income per year</td>
								<td width="100">$100,000</td>
							</tr>
							<tr>
								<td>Per month</td>
								<td><strong>$8,333</strong></td>
							</tr>
							<tr>
								<td>Taxes (25,3%)</td>
								<td>$2,108</td>
							</tr>
							<tr>
								<td>Rent</td>
								<td>$3,000</td>
							</tr>
							<tr>
								<td>Utilities (heating, home internet, mobile phones, etc)</td>
								<td>$500</td>
							</tr>
							<tr>
								<td>Food</td>
								<td>$1,400</td>
							</tr>
							<tr>
								<td>Car loan</td>
								<td>$335</td>
							</tr>
							<tr>
								<td>Gas, service, insurance</td>
								<td>$400</td>
							</tr>
							<tr>
								<td>Purchases (clothes, furniture, books, school supplies, etc)</td>
								<td>$500</td>
							</tr>
							<tr>
								<td>Eating out twice a week</td>
								<td>$300</td>
							</tr>
							<tr>
								<td>Total Spent</td>
								<td><strong>$8,540</strong></td>
							</tr>
							<tr>
								<td>Money Left</td>
								<td style={{ color: 'red' }}>-$210</td>
							</tr>
						</tbody>
					</table>
				</div>
				<div style={{ flex: '1', minWidth: '300' }}>
				<h4>Sceanrio 2</h4>
				<ul>
					<li>a single person</li>
					<li>salary $55,000 (average in Canada)</li>
					<li>no car</li>
					<li>renting one bedroom apartment</li>
				</ul>
				<table className="mb-4" style={{ width: '400px' }}>
					<tbody>
						<tr>
							<td width="300">Income per year</td>
							<td width="100">$55,000</td>
						</tr>
						<tr>
							<td>Per month</td>
							<td><strong>$4,580</strong></td>
						</tr>
						<tr>
							<td>Taxes (21,3%)</td>
							<td>$974</td>
						</tr>
						<tr>
							<td>Rent</td>
							<td>$2,000</td>
						</tr>
						<tr>
							<td>Utilities (heating, home internet, mobile phones, etc)</td>
							<td>$300</td>
						</tr>
						<tr>
							<td>Food</td>
							<td>$500</td>
						</tr>
						<tr>
							<td>Purchases (clothes, furniture, books, etc)</td>
							<td>$250</td>
						</tr>
						<tr>
							<td>Eating out</td>
							<td>$150</td>
						</tr>
						<tr>
							<td>Public transportation</td>
							<td>$100</td>
						</tr>
						<tr>
							<td>Total Spent</td>
							<td><strong>$4,270</strong></td>
						</tr>
						<tr>
							<td>Money Left</td>
							<td style={{ color: 'green' }}>$300</td>
						</tr>
					</tbody>
				</table>
			</div>
			</div>
		</div>
	)
};