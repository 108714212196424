import React, { Fragment, useEffect } from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { logout } from '../../actions/auth';

const Navbar = ({ auth: { isAuthenticated, loading, user }, profile: { profile }, logout }) => {
	const authLinks = (
		<Fragment>
			<li>
				{user && profile && (
					<span>
						<Link to={`/profile/${profile?.user?._id}`} onClick={() => closeMobileMenu()}>
							<img src={profile.avatar ? profile.avatar : user.avatar} className="avatar-show-photo" alt="" />
						</Link>
					</span>
				)}
			</li>
            <li className="nav-item">
				<Link className="nav-link" to="/" onClick={() => closeMobileMenu()}>
		        	Book
				</Link>
			</li>
			{/* <li className="nav-item">
				<Link className="nav-link" to="/#reviews-list" onClick={() => closeMobileMenu()}>
					Reviews
				</Link>
			</li>
			<li className="nav-item">
				<Link className="nav-link" to="/countries" onClick={() => closeMobileMenu()}>
					Countries
					</Link>
			</li>
			<li className="nav-item">
				<Link className="nav-link" to="/survey" onClick={() => closeMobileMenu()}>
					Survey
					</Link>
			</li>
			<li className="nav-item">
				<Link className="nav-link" to="/about" onClick={() => closeMobileMenu()}>
					About
					</Link>
			</li> */}
			<li className="nav-item mr-4">
				<Link className="nav-link" onClick={() => handleLogout()} to="/">
					Logout
				</Link>
			</li>
		</Fragment>
	);

	const guestLinks = (
		<Fragment>
            <li className="nav-item">
				<Link className="nav-link" to="/" onClick={() => closeMobileMenu()}>
		        	Book
				</Link>
			</li>
			{/* <li className="nav-item">
				<Link className="nav-link" to="/#reviews-list" onClick={() => closeMobileMenu()}>
					Reviews
					</Link>
			</li>
			<li className="nav-item">
				<Link className="nav-link" to="/countries" onClick={() => closeMobileMenu()}>
					Countries
					</Link>
			</li>
			<li className="nav-item">
				<Link className="nav-link" to="/survey" onClick={() => closeMobileMenu()}>
					Survey
					</Link>
			</li>
			<li className="nav-item">
				<Link className="nav-link" to="/about" onClick={() => closeMobileMenu()}>
					About
				</Link>
			</li> */}
			<li className="nav-item mr-4">
				<Link className="nav-link" to="/login" onClick={() => closeMobileMenu()}>
					Sing In
				</Link>
			</li>
		</Fragment>
	);

	const closeMobileMenu = () => {
		document.getElementById('navbarMain').classList.remove('show');
	}

	const handleLogout = () => {
		closeMobileMenu();
		logout();
	}

	return (
		<nav className="navbar navbar-expand-sm navbar-dark bg-dark mb-0">
			<Link className="navbar-brand" to="/" onClick={() => closeMobileMenu()}>
			Immigration.live
			</Link>
			<button
				className="navbar-toggler"
				type="button"
				data-toggle="collapse"
				data-target="#navbarMain"
				aria-controls="navbarMain"
				aria-expanded="false"
				aria-label="Toggle navigation"
			>
				<span className="navbar-toggler-icon"></span>
			</button>

			<div className="collapse navbar-collapse" id="navbarMain">
				<ul className="navbar-nav ml-auto">
					{!loading && (
						<Fragment>{isAuthenticated ? authLinks : guestLinks}</Fragment>
					)}
				</ul>
			</div>
		</nav>
	);
};

Navbar.propTypes = {
	logout: PropTypes.func.isRequired,
	auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
	auth: state.auth,
	profile: state.profile
});

export default connect(mapStateToProps, {
	logout,
})(Navbar);
