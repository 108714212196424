import React from 'react';

const chapters = [
            "Foreword",
            "Why did I write this book?",
            "A brief overview of my background",
            "Why Canada",
            "How we immigrated",
            "First impressions and the pros of Canada",
            "What is wrong with Canada",
            "My software engineering journey",
            "Moving to Calgary?",
            "Root causes of the problems of Canada",
            "Healthcare in Canada",
            "Politics and the government of Canada",
            "Why people are still coming to Canada",
            "What Canadian immigration program is really about",
            "Our biggest mistake",
            "The price of real estate in Canada always goes up",
            "Commentators: Stop whining and work harder",
            "Commentators: Property prices and rentals are high everywhere",
            "Commentators: You just don’t know how to budget",
            "If not Canada, then where?",
            "My thoughts on the next steps",
            "Do I think immigrating to Canada is still a Good Idea?",
            "How to fix broken Canada in 3 'easy' steps",
            "Why I am against mass immigration or am I?",
            "Example of the cost of life in Canada",
            "A note on schools in Canada",
            "A note on the basement culture in Canada",
            "A note on my blogging story",
            "A note on sports and activities for children and adults",
            "A note on how to make friends",
            "The problem with IELTS",
            "How to pass the IELTS exam",
            "What other people are saying",
            "Epilogue: pros and cons of Canada."
];

const unauthChapters = [1, 2, 34];

const Chapter = ({ text, index, isAuthenticated }) => {
    const locked = !isAuthenticated && !unauthChapters.includes(index);
    const hrefLink = `#chapter-${index}`;
    return (
       <span className="mb-2">{locked && <i className="fas fa-lock m-2" />}<a href={!locked ? hrefLink : null}>{`${index}. ${text}`}</a></span>
    )
}

export const TableOfContentsList = ({ isAuthenticated }) => {
    return (
        <>
            {chapters.map((text, index) => <Chapter text={text} index={index + 1} isAuthenticated={isAuthenticated} />)}
        </>
    );
};

