import React, { useState } from 'react';
import Select from 'react-select';
import { getSelectOptions } from '../../utils/survey/SurveyHelpers';
import { SURVEY_DATA } from '../../utils/survey/SurveyData';
import Modal from 'react-modal';

export const ReviewFilter = ({ countryKey }) => {
	const [formData, setFormData] = useState({});
	const [filterOpen, setFilterOpen] = useState(false);

	const onChangeSelect = (newValue, key) => {
		setFormData({ ...formData, [key]: newValue.value });
	};

	const onChangeInput = (e, key) => {
		setFormData({ ...formData, [key]: e.target.value });
	};

	const onChangeBool = (e, key) => {
		setFormData({ ...formData, [key]: e.target.checked });
	};

	const countryKeyItem = SURVEY_DATA.section_one[0];

	return (
		<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
			<div style={{ flex: 5, marginRight: '50px' }}>
				{!countryKey &&
					<div className="form-group">
						<small className="form-text">{countryKeyItem.filterTitle}</small>
						<Select
							options={getSelectOptions(countryKeyItem.enum)}
							className={`survey-input-select`}
							type={countryKeyItem.inputType}
							placeholder={'Canada'}
							name={countryKeyItem.title}
							onChange={(newValue) => onChangeSelect(newValue, countryKeyItem.key)}
							isDisabled={true}
						/>
					</div>
				}
			</div>
			<Modal
				isOpen={filterOpen}
				className="review-filter-modal"
			>
				{Object.entries(SURVEY_DATA).map(([sectionKey, sectionValue], sectionIndex) => {
					return (
						<div>
							{sectionValue.map(item => {
								if (!item.filter) {
									return null;
								}
								if (item.enum) {
									return (
										<div className="form-group">
											<small className="form-text">{item.filterTitle}</small>
											<Select
												options={getSelectOptions(item.enum)}
												className={`survey-input-select`}
												type={item.inputType}
												placeholder={'Any'}
												name={item.title}
												onChange={(newValue) => onChangeSelect(newValue, item.key)}
											/>
										</div>
									);
								}
								else if (item.lines) {
									return (
										<div className="form-group">
											<small className="form-text">{item.filterTitle}</small>
											<textarea
												className={`survey-input-${item.type}`}
												type={item.inputType}
												placeholder={item.label}
												name={item.title}
												value={formData[item.key]}
												onChange={(e) => onChangeInput(e, item.key)}
												rows={item.lines}
											/>
										</div>
									);
								}
								else {
									return (
										<div className="form-group">
											<small className="form-text">{item.filterTitle}</small>
											<input
												className={`survey-input-${item.type}`}
												type={item.inputType}
												placeholder={item.label}
												name={item.title}
												value={formData[item.key]}
												onChange={(e) => item.type === 'boolean' ? onChangeBool(e, item.key) : onChangeInput(e, item.key)}
											/>
										</div>
									);
								}
							})}
						</div>
					);
				})}
				<button onClick={() => setFilterOpen(false)}>Search</button>
			</Modal>
			<button className="btn btn-info mt-2 mb-2" disabled="true" type="submit" style={{ flex: 1, height: '38px' }} onClick={() => setFilterOpen(true)}>Filters</button>
		</div>
	);
};
