import React, { Fragment } from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import { connect } from 'react-redux';
import Footer from './Footer';
import { ReviewsList } from '../reviews/ReviewsList';

const PropTypes = require('prop-types');

function Home({ auth: { isAuthenticated, loading } }) {
	return (
		<Fragment>
			<div id="homeImage">
				<div className="container-fluid text-center">
					<div className="row justify-content-center">
						<span className="home-container">
                            <h1>
								Real immigration stories aggregator
							</h1>
							<div className="button-wrapper">
								<span>
								</span>
                                <Link className="btn btn-light lift" to="/#reviews-list">
                                    Reviews
                                </Link>
								<Link className="btn btn-light lift" to="/survey">
									Survey
								</Link>
								<Link className="btn btn-light" to="/countries">
									Countries
								</Link>
							</div>
						</span>
					</div>
				</div>
			</div>
			<ReviewsList />
            <Footer positionClass={'absolute'}/>
		</Fragment>
	);
}

Home.propTypes = {
	auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
	auth: state.auth,
});

export default connect(mapStateToProps)(Home);
