import React from 'react';
import { connect } from 'react-redux';
import "../../assets/css/components/footer.css";

function Footer({ lang: { lang }, positionClass }) {
    return (
        <footer className={`${positionClass} text-center bg-dark`} id="footer">
            {/* <p>Photomap, {year}</p> */}
            <span className="social-wrapper">
                <a href="https://www.youtube.com/channel/UCR3hddRiLMmovapYwmKGlLw" target="_blank" rel="noreferrer"><i className="fab fa-youtube footer-social-icon"></i></a>
                <a href="https://twitter.com/alexpvca" target="_blank" rel="noreferrer"><i className="fab fa-twitter footer-social-icon"></i></a>
                <a href="https://www.instagram.com/alexpvlca/" target="_blank" rel="noreferrer"><i className="fab fa-instagram footer-social-icon"></i></a>
            </span>
        </footer>
    );
}

const mapStateToProps = (state) => ({
    lang: state.lang,
});

export default connect(mapStateToProps)(Footer);
