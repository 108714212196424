import React from 'react';
import { deleteReview, addLike, removeLike } from '../../actions/reviews';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

const ProfileReviewItem = ({
    // eslint-disable-next-line
	review: review,
	auth,
	deleteReview
}) => {
    // eslint-disable-next-line
	let deleteButton = false;

	if (auth.isAuthenticated) {
		if (auth.user._id === review.user) {
            // eslint-disable-next-line
			deleteButton = true;
		}
	}

	if (review.isSurvey) {
		return null;
	}

	return (
		<div className="card mt-3">
			<div className="card-header">
			<strong>
			{/* {review.date.slice(0, 10)}: */}
			</strong>
			</div>
			<div className="card-body">
				<p>{review.text}</p>
			</div>
			<div>
				{/* {Object.entries(SURVEY_DATA).map(([sectionKey, sectionValue], sectionIndex) => {
					{sectionValue.map(item => {
						if (review?.[item.key]) {
							console.log("ZZZ", review[item.key]);
							return (
								<p>
									{review[item.key]}
								</p>
							)
						}
					})}
				})} */}
			</div>
			{/* <div className="card-footer justify-content-end">
				{deleteButton && (
					<button
						className="btn-danger"
						title="Delete Review"
						onClick={() => deleteReview(review._id)}
					>
						<i className="fas fa-times" />
					</button>
				)}
			</div> */}
		</div>
	);
};

ProfileReviewItem.propTypes = {
review: PropTypes.object.isRequired,
auth: PropTypes.object.isRequired,
deleteReview: PropTypes.func.isRequired,
addLike: PropTypes.func.isRequired,
removeLike: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
auth: state.auth
});

export default connect(mapStateToProps, { deleteReview, addLike, removeLike })(
ProfileReviewItem
);
