import React from 'react';
import { CostOfLife } from '../../content/countries/canada/costOfLife';
import { YoutubeComment } from './YoutubeComment';

export const Chapters = ({ isAuthenticated }) => {
	return (
		<div id="book-chapters">
			<p className="book-quote">
				Youtube comment: <br/><br/>
				A message to would-be immigrants to Canada from born Canadians:    Whatever low income/low opportunity (usually warmer) country you come from. .. . we plan to move there once we retire.   We can get so much more lifestyle for our money after retirement outside of Canada.  Probably we will use the money some wealthy (or high savings) immigrant gave us for our home to fund our retirement in their home country.
			</p>
			<div className="chapter" id="chapter-1">
				<h2>Foreword</h2>
				<p>
                    There is so much misery in the world. Many countries suffer from wars, poverty, and authoritarian regimes, generation after generation. It seems as though there is no way out to a brighter future.
                </p><p>
                    But there is a dream that somewhere on this planet, a prosperous country exists with the rule of law and democracy, friendly people, a clean environment, a strong economy, beautiful cities, excellent roads, and opportunities to work hard and build a great life, even from scratch. A country that welcomes honest and hardworking people from all walks of life.
                </p><p>
                    Millions believe that this country is Canada. It attracts about 500,000 new residents each year, and many more millions would do what it takes to secure a place for themselves. An almost utopian picture of prosperity is promoted to less developed countries, inspiring people to put their life savings and great effort into pursuing the dream of becoming Canadian, whether for themselves or for their children.
                </p><p>
                    Does this dream intersect with reality?
                </p><p>
                    Up until 2018 I lived all 30 years of my life in Russia. But that year I strongly decided to move out. There was a wide range of different reasons behind that, mainly politics, but also lack of infrastructure, people's behavior in general, and maybe a bit of my still youthful maximalism. Youtube algorithm was bombarding me with videos that were picturing Canada as a developed country that is welcoming new immigrants and is full of opportunities to work hard and build a good life.
                </p><p>
                Fast forward 6 years to 2024, I’ve got my Canadian citizenship, and I’m working as a Software Engineer with a salary above the average. Although I find Canada to be a bit better than my home country, it’s a very different type of “being better” than I expected. Canada surprised me in a really bad way.
                </p><p>
                    The majority of Canadians are so poor they cannot afford anything but taxes, rent, and food, and this is if they are earning above the average. The cost of living is plain ridiculous. Everything in Canada costs a fortune. Salaries are too low. Houses cost millions and there are too few of them being built. The country is being flooded with immigrants who have nowhere to live and nowhere to work.
                </p><p>
                    Canadian government is not that much better than their counterparts in Russia. They have zero incentive to make the country better, and still, the current Prime Minister has been in power for almost 10 years, and he was just reelected for a third time, which blew my mind.
                </p><p>
                    There is no health care at all. If you want to see a doctor, the only option is to travel to another country, even thought health care is financed from taxes, which are one of the highest in the world.
                </p><p>
                    Canada is currently a complete mess and it’s accelerating its free fall.
                </p><p>
                    What Canada has though is an army of real estate agents and immigration lawyers, who are promoting Canada like it is a dream country to naive citizens of third world countries. Their goal is to make money from those people, definitely not to help them. They show some idealistic pictures of life in Canada which simply do not exist in reality.
                </p><p>
                    Almost all the information that I had used to make my decision on immigration turned out to be highly inaccurate.
                </p><p>
                    Canada has its advantages, and at first, it seemed to me that I moved a hundred years ahead in time. But just after a couple of years, I discovered some really nasty problems in the foundation of the country that did not allow me to call Canada a new home and made me want to leave it.
                </p><p>
                    The dark side of immigration to Canada is that the majority of recent newcomers struggle to build a decent life here. Most are used by the system, part with their money and the most productive years of their lives, only to be cast aside when they cannot be used anymore.
                </p><p>
                    This may not have been the case in the 2000s and even early 2010s, but it definitely is in the early 2020s. Most of the information on the internet and in the blogs is outdated. Canada has changed a lot in recent years, and unfortunately, for the worse. It is clear to me that it has already passed its peak and is currently in decline. This decline could be reversed with smart policies, but I do not see any signs of these policies. On the contrary, it feels as though all branches of government aim to accelerate the decline.
                </p>
                <YoutubeComment comment={["I'm a computer engineer born and raised in Canada. I worked for 2 decades in the high tech sector in Ottawa. I moved to the US at 39 with my wife and 3 children. I had maybe $20k in RRSPs and no debt. My higher wage allowed me to provide a great life for my family, invest in the market and retire at 49. If I had stayed in Canada I would not have been able to retire until 70 - maybe 75.", "Pre-covid there were around 700k Canadian college/university grads that moved to the US for work (TN-1 visa data). It's a bit leap of faith to leave your home country, so the most skilled and confident workers are usually the ones that leave.", "If a large percentage of your most creative and skilled highly educated specialists are leaving the country, it's impossible to get enough successful companies going that are able to compete on the world stage and pay (much) better wages.", "On top of this when you have the absolute best minds that take the most risks and actually create a successful startup in Canada they (almost always) immediately sell out and move to the US. This further ensures that no successful new businesses will be around to pay higher wages and increase demand for skilled high tech workers.", "Canada has been in a terrible position for decades and no politicians have a clue about what is going on, let alone how to fix it."]} />
			</div>

			<div className="chapter" id="chapter-2">
				<h2>Why did I write this book?</h2>
				<p>
                    I recently realized that when I was making the decision to move to Canada, I was getting information almost solely from those who have a business incentive for new immigrants to move - real estate agents, immigration advisers, and the like. Many of them have popular blogs and YouTube channels. Another category is social media influencers who settled in the country years ago, already possess several properties, and know nothing about the struggles of recent newcomers, as the situation has changed dramatically in recent years. Or, even worse, they know but deceive people on purpose, so that they always have some poor family to rent their basement, the price of their properties goes up, or those poor immigrants would buy their services.
                </p><p>
                    Anyways, their information is too skewed to the positive side, and they are far from being realistic and honest. Most of them do not work in the real Canadian economy as employees. They managed to be in the right place at the right time, build some kind of business, maybe buy a couple of properties for a reasonable price, and now they are promoting Canada as if it is an attractive country to immigrate to for ordinary people.
                </p><p>
                    But they are just the tip of the iceberg. Below are millions of poor people with no bright future in this country, at least not in its current state. These people are not influencers; they don't post about their struggles on social media. No one knows about them.
                </p><p>
                    The goal of this book is to fill the information gap. If it saves a single family from making their lives worse, my mission is already accomplished.
                </p>
                <YoutubeComment comment={["My dad worked in Canada for almost 40 years, and when he retired he moved back home(Portugal), while i left Canada back in 06. back in the 90s my dad in working in construction would be making 20 something dollars per hour, and a house would cost like 200k in Toronto.", "When my dad retired in 2018 he was making 38$ per hour while a house in toronto is always over 1million. So in the span of 20 years , his salary did not even double while the cost of housing multiplied various times. Its insane. I work as a software Engineer in Portugal, and even though i could work in Canada since I'm a Canadian Citizen.", "When i do the math like you just did, it does not make any sense, considering i would have to give up 300 days of sunshine per year and need embrace for harsh 6 months of winter... Times are tough everywhere."]} />
			</div>
            {isAuthenticated &&
                <>
                <div className="chapter" id="chapter-3">
                    <h2>A Brief Overview of My Background</h2>
                    <p>
                        I graduated from university in Moscow with a Master’s Degree in Computer Science in 2010, but didn't start the career that was intended for me. Instead, I founded a small travel company in Kamchatka, a far eastern part of Russia where I was born.
                    </p><p>
                        Going to the mountains was my passion at that time, and I saw a business opportunity there. In the first couple of years, it was just me guiding people to the wild nature of Kamchatka, and I was barely able to make enough for a living from it. Later on, I got more clients and hired some people to work for me.
                    </p><p>
                        The travel business was great for me because in a short summer season, I was able to earn many times more than the annual average income in Russia. It helped me to be free for the rest of the year when I was able to work on other potential projects. For example, for several years, I dreamed of opening my hotel. I also started traveling to other countries. One of my favorite destinations was Thailand, where my wife, two daughters, and I spent two winters.
                    </p><p>
                        Although I enjoyed running my travel business, after about 10 years, I was not able to scale it enough to build a solid long-term financial foundation for my family. It was still a small business that required my full attention and involvement. For various reasons, I didn't extend it into something bigger. Competition increased, the flow of clients turned stagnant, my motivation to continue was getting lower, and I was not as interested in the service I was providing as I had been in the beginning.
                    </p><p>
                        At the same time, other doubts were piling up. I saw that the political situation in Russia was deteriorating quickly and was threatening to turn into some version of USSR 2.0. Propaganda and the people were turning too chauvinistic and aggressive. Almost all the political leaders whom I supported were in jail, so I started dreaming of transitioning from living in my beautiful but hardly developed town to a more vibrant city in a developed democratic country. That's how I initially came up with the idea of immigration.
                    </p>
                </div>

                <div className="chapter" id="chapter-4">
                    <h2>Why Canada</h2>
                    <p>
                        Aside from politics, there were other challenges in my home country, such as poor infrastructure, dangerous roads, people being not nice enough, a weak and unstable currency, an unreliable banking system, and a lack of rule of law.
                    </p><p>
                        In search of a better future, I started looking for a developed, democratic country with a strong economy and opportunities. Ideally, the country would be English-speaking and welcoming to immigrants, offering realistic immigration programs and a sense of belonging, even for the first generation.
                    </p><p>
                        I considered several options including the US, Canada, Australia, and New Zealand. However, I could not find an immigration program that fit our needs in the US or Australia. New Zealand seemed too far away. Canada appeared to be the best candidate, as it met all our requirements and, after speaking with an immigration lawyer, we found a way for us to become Permanent Residents while still being in Russia. I simply needed to gather a lot of documents and pass the IELTS English test.
                    </p>
                </div>

                <div className="chapter" id="chapter-5">
                    <h2>How We Immigrated</h2>
                    <p>
                        I spent months reading about Canadian immigration programs on the government website and watching stories of other people on YouTube. I enrolled in a two-year college program in Canada and tried to obtain a study permit, but it was refused.
                    </p><p>
                        From the perspective of the visa officer, my career path was not a good match for going to college. Since I had been managing my small business for 10 years, going to college seemed like a poor decision. I later learned that most people over 30 years old are usually refused a study permit if they plan to attend college. An MBA program might have given me a better chance.
                    </p><p>
                        After living in Canada for a couple of years, I realized that being refused a study permit was a blessing. If I had come to Canada on a student visa or a work permit, it would have been a nightmare, and it is unlikely that I would have achieved citizenship. The cost of living and education in Canada is high, and even with a local education, jobs are scarce. For those lucky enough to find work, salaries are so low that life would be miserable for years. You would probably think it's just temporary and that you just need to work hard for a year or two or five, only to realize 10 years later that your youth is over, your energy level is lower, your bank account is empty, you have loads of debt, and you are renting a crappy apartment in a dark and cold miserable city with no bright future for your family. Unfortunately, that's how I see the fate of the people coming to Canada on a student visa in the early 2020s. Again, it probably was not like that 10 years ago, but it is now.
                    </p><p>
                        But back to the immigration process, after being refused a study visa, a one-hour consultation with an immigration lawyer completely changed my plans and put me on a much quicker and straightforward path to becoming a Permanent Resident through the Express Entry program.
                    </p><p>
                        I was 32 years old, had education and more than five years of experience as the chief manager of a travel company, no matter how small it was. I also had the required minimum amount of money in my bank account. The only thing I needed to do was pass the IELTS.
                    </p><p>
                        Funny enough, the requirement was to have something around $20,000 in my bank account to be eligible to become a Permanent Resident. After immigrating and living in a country for 3 years, I strongly believe that this requirement should be not less than $100,000, or even twice as much. Telling families that they are okay to move with just $20,000 is utter hypocrisy.
                    </p>
                </div>

                <div className="chapter" id="chapter-6">
                    <h2>First impressions and the pros of Canada</h2>
                    <p>
                        We arrived in Vancouver in December 2019—my wife, our two daughters (7 and 5 years old at the time), and I.
                    </p><p>
                        Our first impressions were amazing. The people were friendly, the air was clean, the roads were well-organized, and the drivers were not crazy. The parks were beautiful, and it felt like we had moved 100 years ahead in time—that was the feeling.
                    </p><p>
                        After living in Vancouver for some time, I must say that it is a beautiful city. Many people told me that Canada is a dark, cold, and unfriendly place. That has not been my experience at all. I really enjoy my day-to-day life in Vancouver. The weather is great, people are nice, and the infrastructure is at a very good level—excellent roads, public transportation, parks, playgrounds, and all kinds of sports facilities such as swimming pools, tennis courts, soccer fields, and restaurants serving cuisine from all over the world. There are numerous fantastic places to visit, like trails, parks, lakes, waterfalls, beaches, islands, ski slopes, and campgrounds, with hundreds of spots often no more than an hour's drive from home. A lifetime is not enough to experience everything here.
                    </p><p>
                        People in Canada are friendly, polite, respectful of others, almost never aggressive on the road or anywhere else, thoughtful about the environment, cleanliness of public spaces, and very responsible pet owners. There are no stray dogs, and everyone cleans up after their dog. I still smile every time I think about how fortunate my kids are to be raised in this environment, rather than in our less friendly home country. We felt at home here almost immediately, if you don’t consider the financial part of things.
                    </p><p>
                        I still believe that Canada is a great place to live if we don't consider the issues related to the economy, healthcare, housing, jobs, and cost of living. It may be a good place for someone who settled here a long time ago, has a paid-off mortgage, and a well-paying job. However, that is not the case for new immigrants and the younger generations of Canadians.
                    </p>
                </div>

                <div className="chapter" id="chapter-7">
                    <h2>What is wrong with Canada</h2>
                    <p>
                        During the first few years in Canada, we felt comfortable because we had savings that we brought from home. However, I soon realized that making a living solely in Canada was a huge challenge. As someone with a background in computer science, I was interested in the field and saw it as a way to earn a decent income. Therefore, I decided to become a software engineer.
                    </p><p>
                        After six months of learning how to code through online courses and applying to over a hundred jobs, I finally landed my first job as a Junior Software Engineer, with an annual compensation of $50,000 Canadian dollars (approximately $3,000 per month after taxes). I worked there for a year, while paying $2,700 per month to rent a place to live an hour's drive from downtown for me and my family. Although I was surprised by the imbalance, being new to the country, I still had some savings and thought that things would improve quickly.
                    </p><p>
                        It's not that we rented a luxurious place, and there were options to rent a decent place for less. Perhaps there was an option to rent a simpler apartment for about $2,200, which wouldn't have made much of a difference for us. We had some savings, and one of our reasons for moving to Canada was to live in a comfortable place that was not too expensive but not the cheapest either. Therefore, we were okay with spending an extra $5,000 per year from our savings to have the comfort we wanted as a family with two kids.
                    </p><p></p><p>
                        My second job paid me $75,000, and my wife finished college and started working as a Certified Dental Assistant. However, we were still going through our savings, even though we were not spending money on anything unnecessary. We couldn't afford to travel and were spending all of our money on taxes, rent, and food. The only thing that allowed us to maintain a normal life was the savings we brought from our home country.
                    </p><p>
                        After about 2.5 years, I landed my third job, which paid me a bit over a 100k, and for the first time, we broke even. However, I wasn't thrilled about it because I realized that it was almost impossible to make significantly more. I had friends who were senior developers with ten years of experience, but they only earned between $110,000 and $150,000, which wasn't enough to buy a house and barely enough to take a one-week vacation once a year. And by the way, at this level, a half of a 50k increase in salary is taken by the government in taxes.
                    </p><p>
                        I was shocked to learn that many of my colleagues were in a terrible financial situation. It was normal to spend half of one's income on rent, to rent a basement apartment because a proper apartment was too expensive, and some couldn't afford a car or travel. And I'm talking about engineers with university degrees and years of work experience.
                    </p>
                    <YoutubeComment comment={"I was in Canada for 12 years. It's a great place to be. However, my experience with the job market was totally disappointing. My average salary sounded like a joke, considering the cost of living in Vancouver. The same applied to 90% of the people I knew there. It was like third world earnings, just in a beautiful and expensive scenery. And it just got worse and worse, clearly, year after year, to the point I decided to come back to Brazil, and it was more promising here - at least until before last year's unexpected election results which will cause bankrupcy."} />
                    <p>
                        The cost of living in Canada is high and rising rapidly, while salaries are low and stagnant. This country is no longer the land of opportunity it once may have been. The main component that is contributing to the cost of living is housing, which is insanely expensive in Canada. Just look how Canada is compared to the other G7 countries.
                    </p>
                    <div className="img-container m-2 mb-4">
                        <img src="https://firebasestorage.googleapis.com/v0/b/photomap-9caa6.appspot.com/o/countries%2Feconomy%2F0.jpg?alt=media&token=d1a6000f-5d94-477b-a9d5-6be124a0da5c" alt="" />
                    </div>
                    <div className="m-2 mb-4">
                        <img src="https://firebasestorage.googleapis.com/v0/b/photomap-9caa6.appspot.com/o/countries%2Feconomy%2F1.jpg?alt=media&token=d1a6000f-5d94-477b-a9d5-6be124a0da5c" alt="" />
                    </div>
                </div>

                <div className="chapter" id="chapter-8">
                    <h2>My Software Engineering journey</h2>
                    <p>
                        I heard that software engineers make decent money in the US, and the same might be true in Canada, with the possibility of moving to the US later. During my three years working as a software engineer in Vancouver, I found that the career pays better than the Canadian average, but even that "above-average" salary falls almost below the poverty line, making it difficult to afford homeownership and requiring sacrifices in everyday expenses like groceries.
                    </p><p>
                        The competition for software engineering jobs in Canada is intense, especially in the first five years of a career. The software engineering industry is highly overhyped, with hundreds of candidates competing for each entry-level position. Despite the numerous universities, colleges, coding boot camps, and online courses producing thousands of software developers, there are only a few entry-level jobs available, leading many to switch to other careers.
                    </p><p>
                        Even after a few years of experience, the competition remains tough. Companies are looking for senior, staff, and principal engineers with ten or more years of experience, making 2-3 years of experience an insufficient competitive edge. I've been working as a software engineer for three years, submitted hundreds of job applications, gone through several dozen interviews, and received only a handful of job offers.
                    </p><p>
                        My first job offer came from a Russian-speaking team searching for a junior developer who spoke Russian and was willing to work for a low salary. I took the offer, despite the low pay, as I was desperate to get into the industry and had savings to burn. The company took advantage of my situation.
                    </p><p>
                        My second job offer came through a referral from a friend, and again, the company was looking for someone willing to work for a salary barely enough to get by in the city, almost below the poverty line.
                    </p><p>
                        My third job offer came from a company that approached me. It was a relatively good company with a great team and a good match of their needs with my skills and experience. The salary was decent by Canadian standards, but still not enough to allow me to live in the city long-term. For the first time in my three years here, I stopped burning through my savings.
                    </p><p>
                        In my journey in the Canadian IT industry so far, I've seen many underpaid engineers working just to put food on the table, living in basements, and unable to afford a car. Owning any kind of property in the country is out of the question for the majority of them. I've seen the same story repeated, where companies employ immigrants and pay them just enough for rent and food, not a single dollar more.
                    </p><p>
                        I can't speak for other countries, but I wouldn't recommend studying to become a software engineer in Canada, as this profession does not pay enough to have a decent life in the country.
                    </p>
                    <p className="book-quote">
                        Youtube comment: <br/><br/>
                        I'm a Senior Release Engineer (20+ years of tech experience in Canada) and recently moved from Canada to the USA. You are spot on with respects to the costs in Canada. Unless you have old money in Canada, or your parents can refinance their home to give you a huge chunk for down payment on a home, it's pretty much impossible to live and even get ahead there. It doesn't matter if it's Vancouver, Toronto, Winnipeg, Calgary, Halifax etc... All the places have super high costs associated with them. I really loved your comment near the end about Canadian immigration program as a scam only to support crazy unaffordable housing prices to support older people's inflated house prices. Amazing analysis. I don't think that's the intent but it's how things have evolved.
                    </p>
                </div>

                <div className="chapter" id="chapter-9">
                    <h2>Moving to Calgary?</h2>
                    <p>
                        When I started sharing my struggles on social media, many people commented that Vancouver is not representative of Canada and that my issues were specific to the city. A lot of people suggested I move to Calgary.
                    </p><p>
                        After speaking with a real estate agent and a mortgage broker from Calgary, I discovered that housing is less expensive there, but still not affordable. I would need to pay around $2000 to rent an apartment and more than $2500 to rent a house, which would allow me to save up to $10,000 per year on rent compared to Vancouver. However, salaries in Calgary are generally lower. According to Levels.fyi, a popular website for wages, the median salary for software engineers in Calgary is $80,000, while in Vancouver it is $110,000. Recruiters from Calgary reached out to me several times and they were offering a pay cut around $20,000 to what I have in Vancouver. So while I can save $10k on rent, I would lose at least the same amount with a lower salary.
                    </p>
                    <YoutubeComment comment={"a Software Developer with ~100k salary and could not afford anything outside the bare minimum in Vancouver. So my wife and I moved to Calgary for the housing but later realized Vancouver wasn't the main problem; Canada was. My wife already went back to our home country to scout out possible plans for the future as we don't see it in Canada; at least for now. Some still think Canada is the utopia of the past but the last decade seems to have changed that quite significantly."} />
                    <p>
                        Owning a house is completely out of question in Vancouver Greater Area. Apartments cost from $600,000, townhouses from $800,000, 50-year-old houses from over a million and newer houses from $1.5 million. In Calgary, a house costs roughly from $450,000 to $600,000. With a 5% down payment, the monthly mortgage payment would be more than $3000, or 50% of a six-figure salary. Taxes, housing and food would consume all the income.
                    </p><p>
                        A software engineer working remotely for a Vancouver-based tech company has to pay 50% of their income for a townhouse in affordable Calgary. It is not that Vancouver is unaffordable and Calgary is affordable, but that Vancouver is impossible and the rest of Canada is highly unaffordable and overpriced compared to the income.
                    </p>
                    <p>
                        The only option to make ends meet is probably to work remotely for a tech company from a large expensive city while living in a small, least developed village. Most IT companies are located in large, expensive cities like Vancouver and Toronto. Although some skilled and experienced engineers can work from anywhere, I doubt that I can follow a successful career path while living outside of the main tech hub cities in the early years of my career.
                    </p><p>
                        I applied for several hundred jobs, both in Vancouver and remote, over the last three years and had dozens of interviews. But, I received no offers for completely remote jobs not connected to Vancouver. It is not impossible to get a fully remote position, but it is not automatic, especially for someone without huge experience.
                    </p><p>
                        Now that I have some experience, I hope it will be easier to have a fully remote job while living in a more affordable place. Staying in Vancouver is not an option, and Calgary is still too expensive. So, I propbably should consider moving to Regina or Saskatoon. The problem with these smaller Canadian cities is that it is almost impossible to find any positive reviews from people living there. The ones I find are all negative and blame the lack of jobs, long freezing winters, and overall depression.
                    </p>
                    <YoutubeComment comment={"We changed 2 provinces and it's the same everywhere. Recent immigrants live on the edge of existence. Everything is programmed so nicely that everything you earn is taken away by the government and extremely high cost of living."} />
                </div>

                <div className="chapter" id="chapter-10">
                    <h2>Root causes of the problems of Canada</h2>
                    <p>
                        The root cause of low salaries in Canada is wage suppression. Canada attracts an excessive number of overqualified immigrants, so companies don't need to raise salaries. They can always hire new, well-qualified immigrants who are willing to work for less. New immigrants often don't understand the true cost of living in Canada, have no choice, and believe their situation is temporary.
                    </p>
                    <p>
                        The current government seems to be doing nothing to solve the housing crisis as many members own multiple properties and benefit from rising prices.
                    </p>
                    <p>
                        The government claims that global inflation is the reason for high prices, but the real reason is a deliberate wealth redistribution from the middle class and poor to the elites through the debasement of the Canadian dollar. The government has lowered interest rates, printed money accessible only to the wealthy through credit, and this has enabled them to buy even more properties, making them richer. This money is chasing the same amount of goods and services, inevitably leading to inflation. Therefore, government policies are responsible for inflation in Canada, not the war in Ukraine.
                    </p>
                    <p>
                        The majority of the population, especially young people and new immigrants, struggle to afford the prices of goods and services. Society is split into two groups: those who own assets and those who don't. The government belongs to the first group and has no incentive to fix the problem. The situation is worsening, and for most people, working harder or saving for years is not a viable solution.
                    </p>
                    <p>
                        Real estate gurus and the government suggest buying a one-bedroom condo for the price of a castle and living there for half of one's life. Meanwhile, they own ten properties that they purchased for much less than they are worth today. The government is blocking the construction of new houses with bureaucratic procedures that take years and continuously increasing the flow of new immigrants to the country. This leads to a lack of houses for everyone.
                    </p>
                    <blockquote className="twitter-tweet">
                        <p lang="en" dir="ltr">🇨🇦’s banks: It’s never been harder to buy a home in Canada. We’ve only seen one worse year in 1981 and it takes 36 years to save a downpayment in Toronto.<br/><br/>🇨🇦‘s boomers: it’s always like this. I worked a whole summer for a downpayment, and we all bought in 1981. All of us.</p>&mdash; Stephen Punwasi 🏚️📉🐈☃️ (@StephenPunwasi) <a href="https://twitter.com/StephenPunwasi/status/1600240529790246912?ref_src=twsrc%5Etfw">December 6, 2022</a>
                    </blockquote>
                    <p>
                        Housing costs 10 times more now than it did for the previous generation. A school teacher's family could afford a house 25 years ago, while a doctor's family with a ten times higher income cannot today. This is unsustainable and will inevitably lead to a serious crisis and wealth redistribution. It is not just about new immigrants; people born in Canada have children who are now 25-35 years old, and they cannot afford their own house, often living in their parents' basements.
                    </p>
                    <div className="img-container m-2 mb-4">
                        <img src="https://firebasestorage.googleapis.com/v0/b/photomap-9caa6.appspot.com/o/countries%2Feconomy%2F6.jpg?alt=media&token=d1a6000f-5d94-477b-a9d5-6be124a0da5c" alt="" />
                    </div>
                </div>

                <div className="chapter" id="chapter-11">
                    <h2>Healthcare in Canada</h2>
                    <p>
                        In Canada, there is only state-controlled healthcare, and private clinics are not allowed to exist. The country's attempt to centrally manage the healthcare system is failing, similar to the Soviet Union's failed attempt to manage the economy through central planning.
                    </p><p>
                        To receive any non-emergency medical services, Canadians must first see a family doctor, who can then schedule an appointment with a specialist if necessary. However, I have found it impossible to secure a visit with a family doctor due to a severe shortage, and I have been unable to find a doctor who is accepting new patients.
                    </p><p>
                        As a result, I am completely cut off from non-emergency healthcare services. For those lucky enough to have a family doctor, waiting times to see a specialist are often six months or longer. There have been reports of young people dying while waiting to be seen by a doctor. The only option for me to receive medical treatment is to travel to another country.
                    </p>
                </div>

                <div className="chapter" id="chapter-12">
                    <h2>Politics and the government of Canada</h2>
                    <p>
                        Before moving to Canada, I had a naive belief that the country was democratic and that local politicians worked for the people. However, I have found that this is far from the reality. It appears that none of the politicians work for the people but instead follow their own agendas to maximize their own profits while in power. This applies to everyone, from mayors of small cities to the Prime Minister.
                    </p>
                    <p>
                        So far, I haven't encountered a single political or government official who genuinely wants to improve the lives of Canadians as a whole and not just some small, already privileged subgroups. It's possible that they do exist, but I just haven't met them yet.
                    </p>
                    <p>
                        I find the current Canadian Prime Minister, Justin Trudeau, to be completely incompetent. He seems more concerned with his hair than addressing the pressing issue of the cost of living and housing. No solutions are being proposed and no empathy is shown. He appears to be living in a narcissistic reality, existing within the comfort of his royal residences, private jets, and $6,000 per night hotel rooms. He and his team are completely incompetent for the job.
                    </p>
                    <p>
                        What's even worse is that Canadians seem to be disinterested in politics. They would rather silently agree with any policies imposed by the government, even if they are plainly crazy.
                    </p>
                </div>

                <div className="chapter" id="chapter-13">
                    <h2>Why people are still coming to Canada</h2>
                    <p>
                        Mostly due to the lack of information and a clear understanding of what's really happening in Canada. It took me several years to immigrate and even more time living in the country to fully grasp the situation. While some people may be able to figure it out faster, for most it takes years, and sometimes even decades.
                    </p><p>
                        Even if they do come to the realization, many would not admit it to themselves, let alone publicly, that they made a wrong choice and their lives are now a struggle.
                    </p><p>
                        Another factor is that there are places in the world that are much worse off, with wars and hostile regimes causing people to flee for their safety. These people hope to find success in Canada after escaping, but with the current state of things, their chances are slim. In many cases, they escape a really troubled country, waste all their savings and many productive years of their lives in Canada just to realise that this country is not the place to be either and it’s time to move somewhere again.
                    </p>
                </div>

                <div className="chapter" id="chapter-14">
                    <h2>What Canadian immigration program is really about</h2>
                    <p>
                        At this point, I have begun to view the Canadian immigration program as a government-run scam that is designed to extract money from educated individuals all over the world, who are made to work low-paying jobs so that they can support the housing prices and quality of life for the previous generations of Canadians, as there is nothing else in the Canadian economy that can do that. Once these immigrants can no longer be exploited, they are discarded and replaced with new ones, starting the cycle all over again.
                    </p><p>
                        Why do I use such a strong word and call it a scam? Well, potential immigrants are sold a dream of a prosperous country where they can work hard and build their own Canadian dream. However, in 2023, this is light years away from reality.
                    </p><p>
                        The actual situation is that 99% of new immigrants are working just to afford paying taxes, rent, and food, no matter where they live and work, with almost no prospect of owning anything or making any meaningful progress. The opportunities that existed here 20 and even 10 years ago no longer exist.
                    </p><p>
                        The standard of living for new immigrants in Canada is declining rapidly, with no access to healthcare, housing, and long wait times for any kind of service. Salaries are low, and the cost of living is high.
                    </p><p>
                        These words should start any discussion about immigration to Canada. And then repeated again and again. When this is clear, we can move on to talking about the advantages. It is just A country among the others, with a brutal competition between humans for a very limited number of places under the sun. It’s not that Canadians are first world people with houses, healthcare and a democratic government day and night thinking about how to improve everyone’s lives. It just happens to be a country with no war on its territory, a country with clean air and water, nice people, safe roads, and beautiful urban areas, which is… already a lot by the standards of today’s crazy world. It’s just that opportunities are not currently here.
                    </p>
                </div>

                <div className="chapter" id="chapter-15">
                    <h2>Our biggest mistake</h2>
                    <p>
                    We underestimated the cost of living in Canada and overestimated how much we can earn while working in Canada.
                    </p>
                    <p>
                    I was running my small business in my home country, owned an apartment with no morgage, and had savings to survive 2-3 years without any earnings. I was spoiled by a relatively comfortable financial situation.
                    </p>
                    <p>
                    My plan was to move to Canada, burn some savings in the first 1-3 years, build a career of a Software Engineer that would allow me to cover our expenses in the country.
                    </p>
                    <p>
                    Back in my home country I couldn’t imagine that imbalance between the cost of living and earnings in Canada is so disastrous that even a family of two working adults cannot afford owning a simple house and having a decent life.
                    </p>
                </div>

                <div className="chapter" id="chapter-16">
                    <h2>The price of real estate in Canada always goes up.</h2>
                    <p>
                        When I was considering immigrating to Canada, I watched several YouTube channels led by immigrants from post-Soviet countries. If you search "immigration to Canada" on YouTube in Russian, you will find that the most popular and informative channels are created by real estate agents, real estate investors, or those who sell courses to immigrants on how to find a job, create a resume, and other related topics.
                    </p><p>
                        After arriving in Canada, I switched to listening to English-speaking bloggers to better understand local realities, but I found that all of them were also real estate agents of different kinds. One example is a Vancouver-based real estate agent named Owen Bigland. I read his book and watched several of his videos, including one titled "Why I Am Still Bullish on Vancouver Real Estate." Owen creates excellent content, and I enjoyed reading his book, which provides solid advice on avoiding the news and sticking to long-term investing in the stocks of great companies and high-quality houses.
                    </p><p>
                        Initially, I naively thought that I would be able to follow the advice of these real estate experts and buy my first condo in Canada after settling in the country, and then maybe 1 or 2 more investment properties. However, after living in Canada for 3 years and having an annual household income of over $150,000, I cannot afford to own anything in the Greater Vancouver area. I could move to Calgary and buy a property there, but I would have to pay 50% of my income for that property for 25 years until I fully pay the mortgage. Historically, salaries for software engineers in Canada have not increased by more than 1-2% annually, so the chances of a dramatic increase in my income are close to none.
                    </p><p>
                        I could move to a city like Winnipeg and buy a house there, which would reduce the cost of housing to about 30% of my income. However, there are fewer jobs in Winnipeg, and all the interesting companies in the IT industry are located in cities like Vancouver and Toronto. There is an option to work fully remote, but Winnipeg does not seem like a great place to live for 25 years: it is kind of cold and dark and plain and boring. This might not be a problem for some people.
                    </p><p>
                        So, here is the question I want to ask these real estate experts: if you believe that the price of Canadian real estate will always go up and I, as a software engineer with a $150,000 household income, cannot afford to buy a decent house in an attractive enough area, who are those people that will buy at these prices? I am not saying that there is no one, but I am asking for a description of a typical buyer of Canadian real estate at these prices.
                    </p><p>
                        Owen Bigland often mentions Warren Buffet, who famously said that one should buy stocks of great companies and hold them forever. Owen applies this thesis to Vancouver real estate, arguing that one should buy high-quality condos in Vancouver and hold them forever to do exceptionally well. However, in my view, Owen forgets about one key aspect of Buffet's ideology: buying great companies at fair prices.
                    </p><p>
                        A company might be exceptional, but the market may become too irrational, causing everyone to buy the stocks of that company and driving the price to an exaggerated value that cannot be justified by the company's business. The stock price could remain the same 20 years down the road.
                    </p><p>
                        The same principle applies to housing prices. Vancouver is an amazing city, but there is no way to justify $2 million home prices when the average salary is $50,000, unless the city is being populated only by the rich. This might eventually be the case, but is it ethical to promote the idea of the price always going up to regular people.
                    </p>
                </div>

                <div className="chapter" id="chapter-17">
                    <h2>Commentators: Stop whining and work harder</h2>
                    <p>
                        Imagine a family that moved to Canada 15 years ago. They bought a house and 1-2 investment properties with the salary of only one working adult. I know such families.
                    </p><p>
                        Today, a similar family moves to Canada, with both adults working, but they cannot afford to own any property because salaries are almost the same as 15 years ago, while housing has become three times more expensive. The overall cost of living is higher, and this family of newcomers is barely making ends meet.
                    </p><p>
                        Meanwhile, the first family can retire and make a living just from renting out their investment properties.
                    </p><p>
                        It's simple math. No matter how hard the family of newcomers works, they will not be able to afford even an apartment and will have to either rent indefinitely or leave the country after using up all their savings.
                    </p><p>
                        Canada has turned houses into a speculative instrument and store of wealth, as the country has been actively debasing the Canadian dollar. People are unable to save in dollars because of the rapid devaluation, so they save in houses instead. In the meantime, new immigrants and younger generations of Canadians are completely priced out of the housing market.
                    </p>
                    <p>
                        Classic story:
                    </p><p>
                        1. Person A came to Canada 20 years ago, had a $100k salary, and bought a house for $200k.
                    </p><p>
                        2. Person B came to Canada in 2020, got a $110k salary, but houses cost $1 million, which is out of reach.
                    </p><p>
                        3. Person A tells Person B that they just need to go to a smaller town and work harder.
                    </p><p>
                        4. Person B goes to a smaller town, starts working for $75k as there are fewer jobs there, and they pay less. Houses cost $500k, still unreachable.
                    </p><p>
                        5. Person A is trying to articulate that there is a problem in how the country is being run. They have been working hard, moved to a smaller town, but still had no luck. Person B says again, "You have to work even harder. Nobody is going to give you anything on a silver platter. Stop complaining and just get started and do it."
                    </p><p>
                        6. Person A understands that there is no way to stay in this country and leaves.
                    </p><p>
                        7. Person C enters the country not knowing about the experience of Person B, but listens to the narrative of Person A and takes the place of Person B. They work hard, own nothing, get fooled by the narrative of Person A, and leave the country.
                    </p><p>
                        8. And the cycle repeats.
                    </p><p>
                            Just do the math. There is no way housing prices can keep going up indefinitely while salaries are not growing.
                    </p><p>
                            There is a limit on how hard you can work.
                    </p><p>
                            There is a limit on how many people can go to "smaller cities" as long as all the jobs are in the big cities.
                    </p><p>
                            As a software engineer, what am I going to do in a smaller city? You don't like engineers? Okay, then who can have a job in a small city?
                    </p><p>
                            I'm pretty sure that the majority of the 500,000 new immigrants per year will not find jobs in those mysterious small cities. How amazing are those small Canadian cities that can accommodate and employ 500,000 well-educated people every year?
                    </p>
                </div>

                <div className="chapter" id="chapter-18">
                    <h2>Commentators: Property prices and rentals are high everywhere</h2>
                    <p>
                        That's the government narrative and a complete lie to avoid accountability. Property prices in Canada are high because of the Canadian government's decisions, not because it's "like that everywhere". And those are concrete decisions - interest rates, tax rates, immigration targets, building permits, etc. Put them the right way and prices will go down quickly. It is so easy for a politician to say "Oh, that's just a global issue, completely out my control". Ok, then what the heck are you doing in your job? Pass to someone who is competent. But the truth is, all those decision makers just own multiple properties and they don't want the prices to go down. And so they feed the population with the global inflation nonsense.
                    </p>
                </div>

                <div className="chapter" id="chapter-19">
                    <h2>Commentators: You just don’t know how to budget</h2>
                    <p>
                        Sure, the reason for a catastrophe with the recent cost of living increase is people buying $1.4 protein bars, and not the government policies regarding money printing, interest rates, immigration targets and building permits. It’s surprising how many people in Canada are blind to where the country is going and what is causing it.
                    </p>
                    <YoutubeComment comment={"Canadian here. I have lived in a few countries around the world and when I came back to Canada at the start of the pandemic, I couldn't recognize this once great country. I had to leave and when I was finally about to leave last fall, I did.  Now, I'm living overseas, happier than ever. I'm living in a first world country, top notch world class city, staying at a flat with incredible views and amenities that only. the celebrities, pro athletes, politiicians, gangsters and drug dealers could afford in Canada. I eat out EVERY meal, I haven't cooked since I left Canada. I take Grab everywhere, haven't used public transport yet and I'm having the time of my life. And all of that for less than the price of a studio in the hood in Toronto. I'm sorry to see Canada being destroyed by our beyond incompetent government!"} />
                </div>

                <div className="chapter" id="chapter-20">
                    <h2>If not Canada, then where?</h2>

                    <p>
                        For many lucky people there is an option to return to a home country. Even if it’s a third world country with far smaller salaries, the cost of living there is usually way less expensive, climate if often times better, and if there is no war or another immediate threat to life, it very well can be a prudent choice to move back. A lot of people do come back after some time spent in Canada.
                    </p>
                    <YoutubeComment comment={"What I realized by talking to my friend in Canada: car insurance is very expensive in Canada. My friend pays $2400 per year for his used ford and its for one person. My whole family in US pay $1680 for 4 people and we have 5 cars with 3 of them being luxury.  His phone bill is also $100+ per month for one person. We have 7 people sharing phone plan for $180. It cost him at least $300+ to fly between cities in Canada. I frequently fly for half of that in the US. I feel like lot of companies have monopoly in Canada and they dont want to change it. The market is not technically 'free' and its very regulated."} />
                    <p>
                        Another common direction of immigration from Canada is the US. As a software engineer, I know as a matter of fact, that many people of my profession consider Canada as an intermediate step, they can’t get legal status in the US right away, but they can in Canada. After getting Canadian passport they try to find a way to work in the US, it is still not super easy, but easier than with almost any other passport.
                    </p>
                    <p>
                        After I posted a video titled "Why People Are Leaving Canada" on my YouTube channel, I received hundreds of comments from people sharing their stories of where they are moving to. Responses mentioned places such as Asia, the Middle East, Europe, Mexico, South America, and other regions. It turns out that at least 10% of Canadians live outside the country. Older generations often sell their homes and move to places like Mexico, while young professionals may relocate to the US.
                    </p>
                    <YoutubeComment comment={"Do you know how hard it is for Canadians to become US citizen? I have tried. Dangerous? Sure some parts, much like here. I have spent time in the US to stay it is all dangerous would be a big misconception. Alot of misconceptions from Canadians about the US... fact is in the US salaries are higher, good/services cost less, good weather, less taxes, freedom to make money how you wish ( do what you want with ur land etc), better work life balance and the services are better in every way. They make it hard for us to go because canada wants to keep its taxed slaves. Canadians need to open there eyes, they are blinded by propaganda on how great canada is and how other places are terrible. They mainly use the supposed 'free health care' as this crutch. It's garbage. We do pay(and alot I might add) and the health care is crap if you actually need care. I could go on but..."} />
                </div>

                <div className="chapter" id="chapter-21">
                    <h2>My thoughts on the next steps</h2>
                    <p>
                        Before coming to Canada, we used to spend winters in Thailand and loved the country. With its year-round warm weather, beautiful beaches, friendly people, and affordable travel opportunities to places like Singapore, Malaysia, and Bali, it was hard not to fall in love with it.
                    </p><p>
                        However, there are limited opportunities for a reliable source of income in Thailand and almost no chance for immigration. If you have a remote job and earn a decent salary from a stable country, you can stay there for a fairly long period of time.
                    </p><p>
                        I'm not saying I would live in Thailand forever, but the idea of spending 25 years paying off a mortgage in Winnipeg just to experience long, dark winters sounds like a waste of life, knowing thera are places like Thailand.
                    </p>
                    <p>
                        If not Canada, then where? I don't have the answer. There are probably a hundred different answers for different people. At this point in my life, I'm interested in trying to live the US. I know it may not work out, but I need to experience it for myself.
                    </p><p>
                        The reasoning behind this is that software engineer salaries in the US are at least double what they are in Canada, while housing is twice as affordable. There are also a variety of climate zones, and you don't necessarily have to live in a cold place.
                    </p><p>
                        The US is also home to the strongest economy in the world and many amazing companies to work for.
                    </p><p>
                        I know the saying that the grass is always greener on the other side of the fence, and that I shouldn't confuse tourism with immigration. But I need to try it for myself to know. Let's see how it goes and what my impressions will be if I manage to make it to the US.
                    </p>
                        <p className="book-quote">
                        Youtube comment: <br/><br/>
                        … I work about 10 hours a day 7 days a week to be able to afford rent. I'm 53 years old and I wonder why young people haven't started a revolution in this country yet. Perhaps because our beloved prime minister will lock their bank accounts. Duh. So my only hope is to win the lottery. Green card lottery. So I can go to the USA. I see the houses for sale right across the border in Buffalo, New York for $100k. The same ones I would pay here at least $500k in a shitty town like Welland. So that's that. If you're thinking of immigrating Canada, think long and hard for this is NOT the country it used to be …
                    </p>
                </div>

                <div className="chapter" id="chapter-22">
                    <h2>Do I Think That Immigrating to Canada is Still a Good Idea</h2>
                    <p>
                        It really depends on your personal situation. Let's consider two example cases.
                    </p><p>
                        Case number 1: Staying in your country presents an immediate threat to your family as there is a war going on, or the authoritarian regime is becoming too oppressive, or the level of crime is on the rise. You are a software engineer with experience and you have a couple of hundred thousand dollars in savings. You are ready to live in a cold city like Winnipeg while paying off your mortgage for 25 years. You are okay with having a relatively modest lifestyle, like having a simple car, saving on some purchases, and saving to be able to travel, maybe to a warm country for a couple of weeks once every two years. You value the fact that your family will live in a relatively safe country with no war, low level of crime, and clean air more than having a more rich lifestyle. Then Canada might be a good place for you.
                    </p><p>
                        Case number 2: You already have a good life in your home country and there is no immediate threat to your life. Maybe you don't earn that much, but the cost of living is tolerable, your apartment or house has already been paid off or your monthly payments are not that big. You like your lifestyle and maybe you travel every year or are free to spend your time in some other way that you really enjoy. In this case, I honestly don't see any reason to move to Canada, chasing some mysterious Canadian dream that you probably misunderstand.
                    </p><p>
                        Sure, it is unlikely that you fit exactly into one of these cases; 99% of people would be somewhere in the middle and only you can decide if Canada is really worth it in your particular case. I just suggest you don't believe anyone who describes how attractive or unattractive Canada is. Don't believe me either. Try to gather as much information as possible and assess it using your best judgement. I wish you all the luck in the world on this journey!
                    </p>
                    <YoutubeComment comment={["I lived some time in Toronto and now living in Chicago, in both cases - I work/worked in IT, as software engineer. In Toronto, my salary was around 80k, 1bed was in 400k range. In Chicago, my salary is close to 200k and I can get a 1bed in Lakeview for around 200k too, yearly salary. Also, taxes are lower and city is prettier too - architecture etc.", "I'd never go back to Canada. I'd rather just move back to Europe. I did working holiday in Sydney too, also working as software dev, and similar situation to Canada - low salaries vs expensive living, but at least weather is good. For me, Canada is the most overrated country in the world (lived in Poland, Uk, Australia, Canada and US and visited almost 80 countries)."]} />
                    <p>
                        The only negative pattern of comments that I received under my videos that I can understand and accept (although not agree with) is the following:
                    </p>
                    <p>
                        “You are an immigrant who wants to live in a nice city, have a house, and afford unimaginable things like travel. This is not how it works. Canada allowed you to escape your third-world country, and for that, you have to live in a small Canadian town on the prairies, follow a very strict budget, save on groceries, never eat out, never travel, make no unnecessary purchases, live in a very humble apartment, repeat all that for your whole life, and be grateful that you have clean air and water.”
                    </p>
                    <p>
                        If someone is coming from a completely broken country and is willing to accept that kind of life, then Canada might be a place to be.
                    </p>
                </div>

                <div className="chapter" id="chapter-23">
                    <h2>How to Fix Broken Canada in 3 "Easy" Steps</h2>
                    <p>
                        It is clear to me that Canada has been on a downward trend in recent years and is currently in a deep crisis. However, it is not beyond repair and can be fixed with the right policies. Here is my list of suggested policies:
                    </p><p>
                        <h4>1. Align Housing with Immigration</h4>
                        The housing market in Canada is in a catastrophic state. To restore balance, either housing prices must decrease significantly or salaries must increase. My proposal is to link the number of new immigrants allowed into the country with the number of new homes being built.
                    </p><p>
                        For example, if Canada builds 200,000 new homes in 2023, then in 2024, we allow 200,000 new immigrants into the country. Currently, we are building about 200,000 new homes and bringing in 500,000 new people, which is not sustainable with the already unaffordable housing prices. This practice must change for Canada to thrive once again.
                    </p>
                    <h4>2. Stop Printing Money</h4>
                    <p>
                        The fiat dollar system claims that inflation is good for the economy, but in reality, it is just theft. I was shocked when I saw a sign at Cleveland Dam park in North Vancouver that said the dam was built for $10.7 million in 1955, which equates to $75 million in 2013 dollars.
                    </p><p>
                        The same item that cost $10,700,000 in 1955 now costs 700% more, or 7 times as much, in terms of purchasing power. $1 from 1955 is worth approximately $0.14 today. It is official.
                    </p><p>
                        We trade our most precious resource, our time, for dollars to provide for our families and save for retirement. And then some random dude from the Central Bank decides to debase the dollar and therefore our time by printing more dollars to fund some random government spending, which they eventually have no accountability for.
                    </p><p>
                        This has to stop. The government should be run as a company in a competitve economy. It should be allowed to spend only what it has earned. It should not be allowed to load up on debt so that several future generations will have to work solely on paying it back.
                    </p>
                        <h4>3. Increase Political Accountability</h4>
                        <p>
                            Debates in the Canadian Parliament have become a circus. Parties ask their opponents questions that are never answered. The opposing party always ignores the question and instead accuses the questioner of something else.
                        </p><p>
                            We need to regulate these discussions and require politicians to answer the questions asked. An independent moderator should enforce this and penalise parties who don't answer the questions or address the issue at hand. After a certain number of penalties, a party should be fined. After a certain point, such as for example a 100 unanswered questions, a party should be banned from the next election.
                        </p><p>
                            This would be a great start to rebuilding the country, but unfortunately, I don't see any politicians addressing these issues in a manner that can lead to a resolution. And I don’t think a person should be allowed to serve as a Prime Minister for more than two terms.
                        </p>
                </div>

                <div className="chapter" id="chapter-24">
                    <h2>Why I am Against Mass Immigration or am I?</h2>
                    <p>
                        Those who advocate for increasing the number of new immigrants to large numbers argue that we need them to prevent the economy from collapsing due to the aging population. Without an inflow of new people, there will be a shortage of workers and a decline in GDP. Furthermore, they point out the need to help people from troubled countries and provide them with a better life.
                    </p><p>
                        However, by allowing 500,000 new immigrants into the country while only building 200,000 new homes for decades, while housing prices are already sky-high, we are making everyone except a few investors more miserable and broke.
                    </p><p>
                        Many people from troubled countries invest all of their family's savings into this opportunity, only to find out a few years later that there is no place for them in the Canadian economy, with no jobs to justify the high cost of living. A significant number of them end up returning home broke. Are we really helping them or just pretending to? Does it actually benefit the Canadian economy when they eventually leave? Even if it helps temporarily, is it right to exploit poor immigrants and leave them broke when they can no longer contribute?
                    </p><p>
                        I have seen this scenario play out firsthand. When the war in Ukraine started in February 2022 and Canada announced its CUAET program for Ukrainian refugees, tens of thousands of them arrived. We hosted a family from Kharkiv in our home for a while, but after half a year of the program, I saw many Ukrainians returning home, disappointed with their decision to come to Canada. Life in Canada was less miserable than in war-torn Ukraine, but it was still not worth it.
                    </p><p>
                        My wife's relatives live in Kyiv, and despite the risks of war, their life there is a hundred times better than it would be in Canada. They understand this and choose to stay in Ukraine.
                    </p><p>
                        There are no jobs in Canada that can support the high cost of living for new immigrants. The country has no houses or doctors for young Canadians and new immigrants, despite pretending that it does. Does this actually help them or does it simply reveal that the economy is broken?
                    </p><p>
                        I support immigration, but it needs to be done thoughtfully and with careful consideration. It should not be a matter of bringing hundreds of thousands of people randomly, hoping that they will build houses and jobs for themselves, while being taxed to death by the government.
                    </p>
                </div>

                <div className="chapter" id="chapter-25">
                    <CostOfLife />
                </div>

                <div className="chapter" id="chapter-26">
                    <h2>A Note on Schools in Canada</h2>
                    <p>
                        My two daughters have been attending an elementary school in Canada for three years. They love school and as parents, we have no negative feelings about it. The elementary schools seem to be great here. The teachers are nice and respectful, they don't put pressure on the kids and they teach a well-balanced mix of social and academic skills. The kids have plenty of time to socialize and spend time outside. Everything looks great, as far as I can tell at this point. I can't say anything about the next steps, such as middle and high school.
                    </p><p>
                        My wife attended a one-year program at a local college to become a certified dental assistant. Again, only positive feelings about the experience. They teach all the relevant skills very well and prepare the students for work, doing so in a respectful manner.
                    </p>
                </div>

                <div className="chapter" id="chapter-27">
                    <h2>A Note on the Basement Culture in Canada</h2>
                    <p>
                        When I was searching for our first rental home in Canada, I was quite confused. Many rental postings referred to the "upper floor of the house" or "basement." At first, I couldn't understand how a single house could be rented to two families at the same time.
                    </p><p>
                        I later realized that Canadians often rent out their investment properties to multiple families, or they may live on the upper floor while renting out the basement, or vice versa.
                    </p><p>
                        I still find it strange that in a so-called developed first-world country with ample resources and land, many families have to resort to renting out their basement to new immigrant families just to make ends meet.
                    </p><p>
                        We now rent an old house with a basement. While we have the entire house, I see on our street that other houses are rented out to two families. If another family lived in our basement, it would be incredibly uncomfortable. The soundproofing is poor, and you can hear everything happening below. It would also be awkward to walk around the house after 9 pm, knowing that the other tenants can hear your every step. We would have to share the laundry, driveway, and backyard. The smell of food cooking from one family would permeate the whole house, making it an unpleasant experience. Imaging those families have children. Many families in Canada are forced to endure this type of living situation.
                    </p><p>
                        And Canadians seem to be totally okay with the practice of renting out basements. In their rental postings, they often don't mention that they are renting out a basement and instead call it something else, such as a "suite," "summer house," or "house apartment with a separate entrance," to avoid using the word "basement." However, it's still a basement. The housing market and cost of living have become so skewed that young generations and new immigrants are priced out and may never be able to afford a house. This reality is masked by calling a basement a "suite."
                    </p>
                </div>

                <div className="chapter" id="chapter-28">
                    <h2>A Note on My Blogging Story</h2>

                    <p>
                        In the end of 2022, I posted my first YouTube video describing the pros and cons of immigration to Canada.
                    </p><p>
                        The main surprise for me was the sheer number of supportive comments I received from people of all backgrounds. It was truly remarkable and made me feel like millions of others feel the same way.
                    </p><p>
                        They were describing their personal stories of disillusionment with Canada, very diverse and all imaginable ages, geographies, professions, and points of view. But there was a common place for almost all of them: an incredibly high cost of living in relation to local incomes, real estate turned into an investment vehicle for the rich at the expense of ordinary people, especially new immigrants and young generations of Canadians.
                    </p>
                    <p className="book-quote">
                        Youtube comment: <br/><br/>
                        All my Brazilian friends that went to Canadá in the past 5 years now they are in the us or Portugal, they never seem to tell exactly why they leave Canada, I used to think that Canada was overrated, just because I didn’t buy all that thing about the dreams that they were selling to immigrants.
                    </p>
                    <p>
                        This experience made me realize that it's not just me complaining about the hardships of immigrant life, but rather a very accurate description of the current state of the country.
                    </p><p>
                        At least 95% of the comments were supportive, and there were thousands of them. However, a few people defended the "Canadian Dream" by saying that it's still a great country because you can afford to live here by saving on groceries, not eating out, not having a car, avoiding unnecessary purchases, and not traveling abroad. However, this doesn't sound like a very fulfilling life to me. Is a house in a city like Winnipeg really worth these sacrifices? Maybe.
                    </p><p>
                        Critical thinking made me immigrate from my home country just in time and avoid catastrophic outcomes of the war. The same critical thinking applied to Canadian realities prevents me from calling this country a new home.
                    </p>
                </div>

                <div className="chapter" id="chapter-29">
                    <h2>A Note on Sports and Activities for Children and Adults</h2>

                    <p>
                        This is just a small observation that I want to share, not a big deal really. We live in the Greater Vancouver Area and there's access to amenities like swimming pools, ice rinks, tennis courts, gymnastics gyms, math classes, and soccer fields, all within a 15-minute drive from our residence.
                    </p><p>
                        However, it's almost impossible to take advantage of these facilities because of long waitlists. If you want your child to play tennis, for instance, they'll have to be put on a waitlist for an entire year as all the groups are currently full.
                    </p><p>
                        Similarly, trying to enroll your child in activities like swimming lessons, figure skating lessons, or soccer, will result in being added to a waitlist with a wait time of several months at best.
                    </p><p>
                        The school keeps sending us emails about summer or spring break camps, but by the time I try to enroll my kids, there are already no spots left.
                    </p><p>
                        When our children finally got enrolled in any of these activities, they turned out to be quite expensive and after half a year or so, we found that the quality of all of them was quite low.
                    </p><p>
                        For sports like figure skating or tennis, the instruction was not good enough to even consider participating in competitions or getting close to that level. They were more geared towards teaching the basics and having fun.
                    </p><p>
                        In summary, our experience with all those activities and amenities has been to wait in a wait list for a year, then pay a lot of money, and then after several months or years realize that the quality is really poor and there is rarely a way to proceed further.
                    </p>
                </div>

                <div className="chapter" id="chapter-30">
                    <h2>A Note on How to Make Friends</h2>
                    <p>
                        During the first two years in Canada, making friends was a challenge. Many immigrants find it hard to form new relationships in a new country, and this was a common topic of discussion.
                    </p><p>
                        However, I didn't view this as a problem. After two years of living in Canada, I stumbled upon an opportunity to learn tennis and saw it as a way to exercise while avoiding boredom.
                    </p><p>
                        Tennis turned out to be a fantastic way to socialize. I joined a few local tennis clubs and was able to play 3-5 days a week, almost for free, from April to October and make new friends.
                    </p><p>
                        The tennis community is fantastic, full of friendly, open-minded individuals of all ages and professions who come together to play this great game.
                    </p><p>
                        Through playing tennis, I met a man who was the same age, had the same profession, and was from the same country as me. He also had a child of the same age as mine. We started playing tennis almost every day, then began going to the gym together and even went on holidays with our families.
                    </p><p>
                        I also met a few Canadian friends, one of whom owned a small boat. He took me on a great trip and showed me the best places for canoeing. We had a lot of fun and I gained valuable insights from someone who was born and raised in the city.
                    </p><p>
                        So, my advice to any new immigrant searching for local friends: join a local tennis club, even if you've never played before. It's a great way to exercise, have fun, and it almost guarantees making friends, due to the nature of this wonderful sport.
                    </p>
                </div>

                <div className="chapter" id="chapter-31">
                    <h2>The Problem with IELTS</h2>
                    <p>
                        When I first attempted the IELTS English proficiency test, I was close to passing, but my writing score was just 6.5 out of 9, which was below the required 7. Despite studying fervently for over half a year with various teachers and tutors, I took the exam five more times, but every time, I received the same writing score of 6.5.
                    </p>
                    <p>
                        In my opinion, the IELTS exam is a scam. I am confident that I improved my writing skills during my intensive studies, but I believe the IELTS organizers wanted me and many other victims around the world to retake the exam as many times as possible, as it is a lucrative business for them.
                    </p>
                    <p>
                        Many people on the internet also claim to have taken the IELTS exam over 10 times and consistently received a writing score of 6.5, while receiving straight 9s for other parts of the exam. I believe the reason for this is because the writing part is the easiest for the IELTS organizers to manipulate. The other parts of the exam consist of multiple-choice questions, but the writing score is subjective and can be adjusted at the discretion of the IELTS organizers.
                    </p>
                    <p>
                        I am not pretending that I am super smart and the best writer. But what are the chances of me going to the exam with almost no preparation and getting 6,5, then practicing writing every day for half a year with tutors and books, retaking the exam five more times and getting the same 6.5? How about the chances of thousands of people having the same experience?
                    </p>
                    <p>
                        In my view, IELTS is a corrupt business that preys on vulnerable and often poor individuals, making them pay large amounts of money repeatedly, making them believe that there is something wrong with them. This realization was a huge red flag for me, as it showed that a journey to a supposed democratic country with rule of law starts with the exploitation of the poorest individuals by people from developed countries.
                    </p>
                    <p>
                        After six attempts, three appeals, and thousands of dollars spent on preparation courses, airline tickets, and the exam itself, I finally received my required writing score of 7. A year later, my documents were ready and my family and I purchased one-way tickets to Canada.
                    </p>
                </div>

                <div className="chapter" id="chapter-32">
                    <h2>How to Pass the IELTS Exam</h2>
                    <p>
                        IELTS has four sections - reading, writing, listening and speaking. For each of them you can get scores from 1 to 9. As I already mentioned, I seated the exam 6 times and the last time I got 9s for reading and listening, 8 for speaking and 7 for writing.
                    </p><p>
                    How did I prepare? The key is to understand that passing IELTS is not about how well do you know English. It is too vague of a metric. To succeed in the exam you need to practice specifically for the exam. A vague goal of “improving your English” can take years and after that you still might not get the needed score. Even a native speaker would probably not get the needed score without studying 
                    specifically how to pass the exam.
                    </p><p>
                    I’ll break down how to prepare for each part of the exam:
                    </p><p>
                    <strong>Reading:</strong> This part consists of multiple choice questions based on some text. The best way to prepare is to download all the existing variants from real exams and go through one exam per day. When I was preparing for the exam, the only source of real exam variants were Cambridge books. I used them and went through a single exam per day, each taking about an hour. I didn't prepare much for the reading as I was already used to reading books in English, and this part was the easiest for me. I trained my brain to avoid mistakes by going through multiple real exams, and later got straight 9s for the reading part.
                    </p><p>
                    <strong>Listening:</strong> To prepare, download Cambridge books and go through a single listening part every day. After completing them, start over and repeat. This is the only solid way to improve your score. By doing this consistently, I noticed fewer mistakes each time and was able to train my brain to understand the type of questions being asked by the IELTS.
                    </p><p>
                    <strong>Speaking:</strong> This part is a 10-minute conversation with a real person and there are no multiple choice questions. From my experience, the best way to learn to speak is to speak. I found a list of 50 questions that are usually asked in IELTS and rehearsed them by talking to myself, while driving, walking or during any spare time. Talking to yourself for 30 minutes every day for 30 days will significantly improve your speaking skills.
                    </p><p>
                    <strong>Writing:</strong> This is the trickiest part. To succeed in this section, you need to find a former IELTS examiner who understands how the writing part is evaluated and can help you write an ideal IELTS-style response. They will go through multiple of your writing exercises, pointing out all mistakes, and you correcting them. I used a service from a British school called IELTS Advantage and an examiner named Chris. He is an expert in his field and helped me improve my writing score. Other books, YouTube channels, tutors and teachers didn't help, but working with an actual IELTS examiner was the key to success in the writing section.
                    </p>
                    <p>Most importantly, don't forget that the goal of the IELTS examiners is to make you fail and pay again. View your multiple attemts as being persistent and always make appeals if your are half a point away from the needed score.</p>
                </div>

            </>
        }
		</div>
	);
};