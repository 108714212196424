export const firebaseConfig = {
    apiKey: 'AIzaSyCLvkrl56m6_aXPfaxXkBUzcjwXwk6BRc8',
    authDomain: 'photomap-9caa6.firebaseapp.com',
    databaseURL: 'https://photomap-9caa6.firebaseio.com',
    projectId: 'photomap-9caa6',
    storageBucket: 'photomap-9caa6.appspot.com',
    messagingSenderId: '1049128653672',
    appId: '1:1049128653672:web:17dc7683179476add27cdd',
  };

  export const mapboxConfig = {
    accessToken: 'pk.eyJ1IjoiYWxleHZhbiIsImEiOiJja2JyZ3V5amcwZXA4MnNvZTExeXliY3MxIn0.h4MnIZmh1ANGwnAunqJe2Q'
  }