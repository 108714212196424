import React from 'react';
import { TableOfContentsList } from './TableOfContentsList';

export const TableOfContentsHard = ({ isAuthenticated }) => {
    return (
        <div id="table-of-contents" className="mb-4">
            <h2>Table of Contents</h2>
            <TableOfContentsList isAuthenticated={isAuthenticated} />
        </div>
    );
};