import { COUNTRIES, FAMILY, MONEY, NET_WORTH } from './enums';

const STARTING_YEAR = 1960;

export const getSelectOptions = (enumType) => {
    switch(enumType) {
        case 'year':
            return getYearOptions();
        case 'country':
            return getOptionsFromJson(COUNTRIES);
        case 'rate':
            return getRateOptions();
        case 'family':
            return getOptionsFromJson(FAMILY);
        case 'money':
            return getOptionsFromJson(MONEY);
        case 'netWorth':
            return getOptionsFromJson(NET_WORTH);
        default:
            return null;
    }
};

const getYearOptions = () => {
    const currentYear = new Date().getFullYear();

    const options = [];

    for (let i = STARTING_YEAR; i <= currentYear; i++) {
        options.unshift({
            value: i.toString(),
            label: i
        });
    }
    return options;
};

const getOptionsFromJson = (jsonInput) => {
    const options = [];

    Object.keys(jsonInput).forEach((key) => {
        options.push({
            value: key,
            label: jsonInput[key]
        });
    });

    return options;
};

const getRateOptions = () => {
    const options = [];
    for (let i = 10; i >= 1; i--) {
        options.push({
            value: i,
            label: i.toString()
        })
    }
    return options;
}
