// PHOTOS FOR PROFILE
export const ALL_PHOTOS_BY_PROFILE_FETCHED = 'ALL_PHOTOS_BY_PROFILE_FETCHED';
export const ALL_PHOTOS_BY_PROFILE_FETCHING_ERROR =
	'ALL_PHOTOS_BY_PROFILE_FETCHING_ERROR';

// CURRENT PHOTO
export const CURRENT_PHOTO_FETCHED = 'CURRENT_PHOTO_FETCHED';
export const CURRENT_PHOTO_FETCHING_ERROR = 'CURRENT_PHOTO_FETCHING_ERROR';
export const CURRENT_PHOTO_CLOSED = 'CURRENT_PHOTO_CLOSED';
export const CURRENT_PHOTO_CLOSING_ERROR = 'CURRENT_PHOTO_CLOSING_ERROR';
export const CURRENT_PHOTO_PROFILE_FETCHED = 'CURRENT_PHOTO_PROFILE_FETCHED';
export const CURRENT_PHOTO_PROFILE_FETCHING_ERROR = 'CURRENT_PHOTO_PROFILE_FETCHING_ERROR';
export const CURRENT_PHOTO_SHOW_ON_MAP = 'CURRENT_PHOTO_SHOW_ON_MAP';
export const CURRENT_PHOTO_SHOW_ON_MAP_FINISH = 'CURRENT_PHOTO_SHOW_ON_MAP_FINISH';

// FEED
export const FEED_PHOTOS_FETCHED = 'FEED_PHOTOS_FETCHED';
export const FEED_PHOTOS_FETCHING_ERROR = 'FEED_PHOTOS_FETCHING_ERROR';
export const FEED_PHOTOS_LOADING_COMPLETED = 'FEED_PHOTOS_LOADING_COMPLETED';
export const FEED_CLEARED = 'FEED_CLEARED';

// FIREBASE PHOTO
export const FIREBASE_PHOTO_UPLOADED = 'FIREBASE_PHOTO_UPLOADED';
export const FIREBASE_PHOTO_UPLOADING_ERROR = 'FIREBASE_PHOTO_UPLOADING_ERROR';
export const FIREBASE_PHOTO_UPLOADING = 'FIREBASE_PHOTO_UPLOADING';
export const FIREBASE_PHOTO_DELETED = 'FIREBASE_PHOTO_DELETED';
export const FIREBASE_PHOTO_DELETING_ERROR = 'FIREBASE_PHOTO_DELETING_ERROR';

// MONGO PHOTO
export const MONGO_PHOTO_UPLOADED = 'MONGO_PHOTO_UPLOADED';
export const MONGO_PHOTO_UPLOADING_ERROR = 'MONGO_PHOTO_UPLOAD_ERROR';
export const MONGO_PHOTO_UPDATED = 'MONGO_PHOTO_UPDATED';
export const MONGO_PHOTO_UPDATING_ERROR = 'MONGO_PHOTO_UPDATING_ERROR';
export const MONGO_PHOTO_DELETED = 'MONGO_PHOTO_DELETED';
export const MONGO_PHOTO_DELETING_ERROR = 'MONGO_PHOTO_DELETING_ERROR';

// MAP PHOTO OVERVIEW
export const MAP_PHOTOS_OVERVIEW_LOADED = 'MAP_PHOTOS_OVERVIEW_LOADED';
export const MAP_PHOTOS_OVERVIEW_LOADING_ERROR =
	'MAP_PHOTOS_OVERVIEW_LOADING_ERROR';

// TRAVEL LINK LEGACY

export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';

export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';

export const USER_LOADED = 'USER_LOADED';
export const AUTH_ERROR = 'AUTH_ERROR';

export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAILED';

export const LOGOUT = 'LOGOUT';

export const GET_PROFILE = 'GET_PROFILE';
export const PROFILE_ERROR = 'PROFILE_ERROR';

export const GET_PROFILES = 'GET_PROFILES';

export const UPDATE_PROFILE = 'UPDATE_PROFILE';

export const CLEAR_PROFILE = 'CLEAR_PROFILE';

export const SET_LANG = 'SET_LANG';

export const TOGGLE_MOBILE_MENU = 'TOGGLE_MOBILE_MENU';

export const GET_REVIEWS = 'GET_REVIEWS';
export const ADD_REVIEW = 'ADD_REVIEW';
export const DELETE_REVIEW = 'DELETE_REVIEW';
export const REVIEW_ERROR = ' REVIEW_ERROR';

export const UPDATE_LIKES = 'UPDATE_LIKES';

export const ADD_COMMENT = 'ADD_COMMENT';
export const DELETE_COMMENT = 'DELETE_COMMENT';

export const GET_REVIEW = 'GET_REVIEW';

export const SUBMIT_SURVEY = 'SUBMIT_SURVEY';
