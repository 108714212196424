import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Spinner from '../layout/Spinner';

export const StripeSuccess = () => {
	const [loading, setLoading] = useState(true);

	useEffect(async () => {
		const email = await localStorage.getItem('readcanadabook');
		console.log("# email", email);

		if (email) {
			localStorage.setItem('readcanadabook-auth', true);
		}
		setLoading(false);
	}, []);

	if (loading) {
		return (
			<Spinner />
		);
	}

	return (
		<div>
			<h1>Thank you for your payment!</h1>
			<div>
				<Link to='/book'>Start reading</Link>
			</div>
		</div>
	);
};