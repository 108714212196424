import React, { useEffect, useState } from 'react';
import { Chapters } from './Chapters';
import { Comments } from './Comments';
import { LeftMenu } from './LeftMenu';
import '../../assets/css/components/book.css'
import { TableOfContentsHard } from './TableOfContentsHard'
import { connect } from 'react-redux';
import { SERVER } from '../../actions/helper';
import axios from 'axios';
import Modal from 'react-modal';
import { login } from '../../actions/auth';
import { loadImage } from 'browser-image-compression';
import Spinner from '../layout/Spinner';
import { BooksSold } from './BooksSold';

const PropTypes = require('prop-types');

function Book({ login, isAuthenticated }) {
	const [isMenuOpen, setIsMenuOpen] = useState(false);
	const [isPurchaseModalOpen, setIsPurchaseModalOpen] = useState(false);
	const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
	const [loading, setLoading] = useState(false);
	const [email, setEmail] = useState();
	const [booksSoldTotal, setBooksSoldTotal] = useState();
	const [booksSoldToday, setBooksSoldToday] = useState();

	const onEnterLogin = () => {
		setIsLoginModalOpen(false);
		login({ email: email, password: 'claim-already-purchased' });
	};

	useEffect(async () => {
		// Check to see if this is a redirect back from Checkout
		const email = await localStorage.getItem('readcanadabook');
		const query = new URLSearchParams(window.location.search);

		if (!email) {
			setLoading(false);
			return;
		}

		if (query.get("success")) {
			console.log("# Order placed!");
			login({ email: email, password: 'book-purchase-success' });
		}

		if (query.get("# canceled")) {
			console.log("Order canceled");
			setLoading(false);
		}
	}, []);

	useEffect(() => {
		function updatePageCounter() {
			const pageCounterDiv = document.getElementById("page-counter");

			if (!pageCounterDiv) {
				return;
			}

			pageCounterDiv.innerHTML = Math.round(window.pageYOffset / window.innerHeight) + 1;
		}

		window.onload = updatePageCounter;
		window.onscroll = updatePageCounter;
	}, []);

	useEffect(async () => {
		try {
			const config = {
				headers: {
					'Content-Type': 'application/json',
				},
			};
			const res = await axios.get(`${SERVER}/api/users/count`, null, config);
			setBooksSoldTotal(res?.data?.total);
			setBooksSoldToday(res?.data?.today);
		} catch (error) {
			console.log(error);
		}
	}, []);

	const onEmailChanged = (e) => {
		const newEmail = e.target.value;
		setEmail(newEmail);
	}

	const onEnterStripe = () => {
		localStorage.setItem('readcanadabook', email);
	}

	if (loading) {
		return (
			<Spinner />
		)
	}

	return (
		<>
			<div id="book-page-container">
				<div className="cover">
					<h1>Want to Immigrate to Canada?</h1>
                    <h1>Or US/Australia?</h1>
					<h1>Read this before you go</h1>
					<h2>How and why countries are</h2>
					<h2>scamming new immigrants</h2>
					<br></br>
					<h3>from a recent immigrant</h3>
					<h2></h2>
					{!isAuthenticated &&
						<>
							<button type="submit" className="buy-button mb-4" onClick={() => setIsPurchaseModalOpen(true)}>
								Buy the e-book for <s>$19.98</s><span className="book-price-active">$9.99</span>
							</button>
							<BooksSold total={booksSoldTotal} today={booksSoldToday} />
							<div style={{ color: 'white' }}>
								Already purchased?
								<button className="login-button" onClick={() => setIsLoginModalOpen(true)}>Login here</button>
							</div>
						</>
					}
					<div className="last-updated mb-2">Last updated: June 2, 2024</div>
					<div className="last-updated mb-4">For feedback: readcanadabook@gmail.com</div>
					{isAuthenticated && (
						<a href='https://drive.google.com/file/d/1ZgnZ1FAbj0NBfpkFUIhGVZpsN3eqDXWS/view?usp=share_link'>
							<button className="login-button">Download PDF</button>
						</a>
					)}
				</div>
				<div className="book-container">
					<div id="page-counter"></div>
					{!isMenuOpen ?
						(
							<div id="menu-button-open" onClick={() => setIsMenuOpen(true)}>
								<i className="fas fa-chevron-right" />
							</div>
						) :
						(
							<div id="menu-button-close" onClick={() => setIsMenuOpen(false)}>
								<i className="fas fa-chevron-left" />
							</div>
						)
					}
					<div id="page-up" onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>
						<i className="fas fa-chevron-up" />
					</div>
					{isMenuOpen && <LeftMenu isAuthenticated={isAuthenticated} />}

					<div className="chapter">
						<h2>Introduction</h2>
                        <p>
                            I moved my family from Russia to Canada in 2019, at the age of 31. As I’m writing this in 2024, I’ve already become a Canadian citizen, I managed to get a morgage in Greater Vancouver, I have a decent job with a salary above the average, I have friends and I actually enjoy my day-to-day life here.
                        </p><p>
                            Despite all that, I deeply regret moving to Canada. I would never have moved if I knew what I know now. My decision was based on completely wrong information, and I’m currently working on a plan to leave.
                        </p><p>
                            If you are thinking about moving to Canada, please read my brief story before you go. It might save your life savings and, more importantly, the most valuable time of your life. I think what I'm writing about could be apllied to some other countries as well, for example US and Australia, as they seem to have simular problems.
                        </p><p>
                            This short book is a collection of articles where I included everything I learned about immigrating to Canada during the last five years. From the initial idea to immigrate, to execution, facing challenges, overcoming them, landing in the country, getting first impressions, and evolving them through experience.
                        </p><p>
                            Reading this book should not take more than an hour or two, and by doing so, you can learn everything I have learned in the last five years. I deliberately tried to make it short, including only the most important thoughts and outcomes.
                        </p><p>
                            Some chapters became videos on my YouTube channel, some did not. I continue to add new articles as my understanding of Canada evolves.
                        </p><p>
                            This book is not only my opinion. I received thousands of comments on my videos, and I included the most insightful ones in the book, where people with decades of experience condensed their wisdom into several sentences. I suppose those comments are even more valuable than my thoughts. You would not find this kind of information anywhere else.
                        </p><p>
                            My message here is not that no one should move to Canada, but rather that you need to better understand the problems I am describing here to make the right choice.
                        </p>
					</div>
					{/* <div className="mb-4">
						<div><span><strong>The book:</strong> 56 pages</span><i className="far fa-file-word m-2" /></div>
						<div><span><strong>Best comments:</strong> 30 pages</span><i className="far fa-file-word m-2" /></div>
					</div> */}

					<TableOfContentsHard isAuthenticated={isAuthenticated}/>
					<Chapters isAuthenticated={isAuthenticated}/>

					{isAuthenticated && <Comments />}
					<div className="chapter" id="chapter-34">
						<h2>Epilogue. Pros and Cons of Canada.</h2>
						<br/>
						<h3>Pros:</h3>
						<ul>
							<li>
								A wide range of immigration programs;
							</li><li>
								Easier to obtain permanent residency and citizenship than in the US;
							</li><li>
								Canadian passport can provide greater access to the "free world";
							</li><li>
								Easy to adapt, as it is a country of immigrants;
							</li><li>
								Friendly people;
							</li><li>
								Good infrastructure;
							</li><li>
								Family-friendly;
							</li><li>
								Environmentally-friendly;
							</li><li>
								Relatively safe, with low crime rates;
							</li><li>
								Beautiful nature;
							</li>
						</ul>

						<h3>Cons:</h3>
						<ul>
							<li>
								High cost of living;
							</li><li>
								Extremely high housing costs;
							</li><li>
								High taxes;
							</li><li>
								Low salaries;
							</li><li>
								Massive influx of over-qualified immigrants;
							</li><li>
								Lack of jobs that pay high enough to cover the high cost of living;
							</li><li>
								Reckless government spending leading to high inflation;
							</li><li>
								People have a "heads in the sand" mentality about politics and the economy;
							</li><li>
								Falling standards of living for young generations and new immigrants;
							</li><li>
								Government policies that tax to the point of having nothing left;
							</li><li>
								Cold climate.
							</li>
						</ul>
						<p>
                            I enjoy my time in Canada, I met many great people here, there are some beautiful parks and well designed cities, but it is not worth to losing financial freedom and opportunities in developing countries.
						</p>
						<br/><br/><br/><br/>
						<p>Vancouver, Canada. Last updated: June 2, 2024.</p>
						<div className="last-updated mb-4">For feedback: readcanadabook@gmail.com</div>
					</div>
				</div>
			</div>
			<div id='testtest' style={{ display: 'flex', flexDirection:' row', justifyContent: 'center', alignItems: 'center' }}>
				<Modal isOpen={isPurchaseModalOpen} className="stripe-modal">
					<div className='buy-modal-close-button-container'>
						<div className='buy-modal-close-button' onClick={() => setIsPurchaseModalOpen(false)}>
							<i className="fas fa-times fa-2x"></i>
						</div>
					</div>
					<form action={`${SERVER}/api/stripe/create-checkout-session`} method="POST" className='buy-modal-container'>
						<h3>Please enter your email</h3>
						<input
							type='email'
							name='email'
							id='email'
							value='email'
							onChange={onEmailChanged}
							required
							placeholder='Enter your email'
							value={email}
							className='buy-email-input'
						/>
						<button type="submit" className="start-button mb-4" onClick={onEnterStripe}>
							Start reading now
						</button>
					</form>
				</Modal>
				<Modal isOpen={isLoginModalOpen} className="stripe-modal">
					<div className='buy-modal-close-button-container'>
						<div className='buy-modal-close-button' onClick={() => setIsLoginModalOpen(false)}>
							<i className="fas fa-times fa-2x"></i>
						</div>
					</div>
					<div className='buy-modal-container'>
						<h3>Please enter your email</h3>
						<input
							type='text'
							name='email'
							value='email'
							onChange={onEmailChanged}
							required
							placeholder='Enter your email'
							value={email}
							className='buy-email-input'
						/>
						<button type="submit" className="start-button mb-4" onClick={onEnterLogin}>
							Start reading now
						</button>
					</div>
				</Modal>
			</div>
		</>
	);
};

Book.propTypes = {
	login: PropTypes.func.isRequired,
	isAuthenticated: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
	isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, { login })(Book);
